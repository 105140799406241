import React from "react";
import { useState } from "react";
import {
  balconyCarpenterServicDetails,
  bedCarpenterServicDetails,
  doorCarpenterServicDetails,
  tvCarpenterServicDetails,
} from "../../Services/ServicesList";

const CarpenterDetailsModal = (props) => {
  const { modalOptions, onCloseModal, handleRequestQuote } = props;
  const [selectedId, setSelectedId] = useState();
  const closeModal = () => {
    onCloseModal();
  };

  const viewDetails = (showDetailsID) => {
    setSelectedId(showDetailsID);
  };

  return (
    <div
      className={`modal centered_Modal detailedModal selection_modal detailed_modal ${
        modalOptions === true ? "show" : ""
      }`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Carpenter Services
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
              onClick={closeModal}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div
            className="modal-body"
            data-spy="scroll"
            data-target="#myScrollspy"
            data-offset="1"
          >
            <div className="service_details_s">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="single_service_tabs">
                      <nav className="sservice_tabs_ul" id="service_scrollspy">
                        <ul className="nav">
                          <li className="nav-item">
                            <a className="nav-link active" href="#balcony">
                              Balcony
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#bed">
                              Bed
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#door">
                              Door
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#tv">
                              TV
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="service_tab_scroll">
                      <div id="balcony" className="single_service_tab_scroll">
                        <h2>balcony</h2>
                        {balconyCarpenterServicDetails &&
                          balconyCarpenterServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Balcony",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Balcony",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      <div id="bed" className="single_service_tab_scroll">
                        <h2>Bed</h2>
                        {bedCarpenterServicDetails &&
                          bedCarpenterServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Bed",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Bed",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      <div id="door" className="single_service_tab_scroll">
                        <h2>Door</h2>
                        {doorCarpenterServicDetails &&
                          doorCarpenterServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Door",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Door",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      <div id="tv" className="single_service_tab_scroll">
                        <h2>TV</h2>
                        {tvCarpenterServicDetails &&
                          tvCarpenterServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "TV",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "TV",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {balconyCarpenterServicDetails &&
                      balconyCarpenterServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    {bedCarpenterServicDetails &&
                      bedCarpenterServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    {doorCarpenterServicDetails &&
                      doorCarpenterServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    {tvCarpenterServicDetails &&
                      tvCarpenterServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarpenterDetailsModal;
