import React from "react";
import Card from "../../UI/Card";
import OpenPlotsModal from "../Modals/OpenPlotsModal";
import FlatsApartmentsModal from "../Modals/FlatsApartmentsModal";
import IndividualHousesModal from "../Modals/IndividualHousesModal";
import FarmLandsModal from "../Modals/FarmLandsModal";

const AssetValidation = (props) => {
  return (
    <React.Fragment>
      <div
        className="services_container my-5"
        id="serviceAssetssearchvalidationservices"
      >
      <div className="container">
        <div className="main_heading text-center">
          <h2>Asset Search &amp; Validation Services</h2>
        </div>
        <Card>
          <div className="row">
            <div className="col-md-4">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#open_plots_modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/plot.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>open Plots</p>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#apartments_modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/flats.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Flats/Apartments/Individual homes</p>
                </div>
              </a>
            </div>
            {/* <div className="col-md-3">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#individual_houses_modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/home.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Individual homes</p>
                </div>
              </a>
            </div> */}
            <div className="col-md-4">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#farm_lands_modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/farmland.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Farm Lands</p>
                </div>
              </a>
            </div>
          </div>
        </Card>
       </div>
      </div>
      <OpenPlotsModal />
      <FlatsApartmentsModal />
      <IndividualHousesModal />
      <FarmLandsModal />
    </React.Fragment>
  );
};

export default AssetValidation;
