import React from "react";
import { useState } from "react";
import Card from "../../UI/Card";
import LoanServicesModal from "../Modals/LoanServicesModal";
import Section from "../Section";

const LoanServices = (props) => {
  const [modalShow, setModalShow] = useState(false);

  const closeModal = () => {
    setModalShow(false);
  };
  return (
    <Section className="home_services" id="serviceLoansCreditCards">
      <div className="main_heading text-center">
        <h2>Loans & Credit Cards</h2>
      </div>
      <div className="container">
        <Card>
          <div className="row">
            {props.ServicesList &&
              props.ServicesList.map((service, i) => (
                <div className="col-md-custom-7" key={i}>
                  <a
                    className="single_service"
                    href="#"
                    data-toggle="modal"
                    data-target={`#loanModal${service.id}`}
                  >
                    <div className="service_icon">
                      <span className="service_ico">
                        <img src={`images/${service.serviceIcon}`} alt="img" />
                      </span>
                    </div>
                    <div className="service_info">
                      <p>{service.serviceName}</p>
                    </div>
                  </a>
                  <LoanServicesModal
                    setModalOption={modalShow}
                    clickedService={service}
                    onModalClose={closeModal}
                  />
                </div>
              ))}
          </div>
        </Card>
      </div>
    </Section>
  );
};

export default LoanServices;
