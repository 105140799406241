import { Formik } from "formik";
import React, { useState } from "react";
import EnquiryMessage from "../../Utils/EnquiryMessage";
import FormikControl from "../Common/Formik/FormikControl";
import { Locations, LeafletDistributionValues } from "../Common/InitialValues";
import { BannerPastingServiceValiation } from "../Common/Validations";

const LeafletDistributionServiceModal = () => {
  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedService, requestFrom) => {
    const {
      fullName,
      emailAddress,
      mobileNo,
      Location,
      purposeOfCampaign,
      startDate,
      endDate,
    } = values;
    const user_enquiry = `${selectedService} - Location : ${Location} - Purpose of campaign : ${purposeOfCampaign} - Start Date : ${startDate} - End Date : ${endDate}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <div className="modal centered_Modal selection_modal" id="leaflet_modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Leaflets Distributon
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={LeafletDistributionValues}
                validationSchema={BannerPastingServiceValiation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(
                    values,
                    "Leaflets Distributon",
                    requestType
                  );
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="select"
                            label="Your Location"
                            name="Location"
                            onChange={handleChange}
                            options={Locations}
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Purpose of campaign"
                        name="purposeOfCampaign"
                        className="pest_service"
                        onChange={handleChange}
                        options={[
                          { _id: "aspc0", label: "Educational Institutions" },
                          { _id: "aspc1", label: "Hospitals" },
                          { _id: "aspc2", label: "Mobile Stores" },
                          { _id: "aspc3", label: "Retail Stores" },
                          { _id: "aspc4", label: "Real Estate" },
                          { _id: "aspc5", label: "Car/Bike Showrooms" },
                          { _id: "aspc6", label: "Restaurants" },
                        ]}
                      />
                      <div className="row serviceDate">
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="date"
                            label="Start Date"
                            name="startDate"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6 pl-1 pr-0">
                          <FormikControl
                            control="input"
                            type="date"
                            label="End Date"
                            name="endDate"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeafletDistributionServiceModal;
