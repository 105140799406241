import React, { Fragment } from "react";
import ACServiceModal from "./ACServiceModal";
import GeyserServiceModal from "./GeyserServiceModal";
import MicrowaveServiceModal from "./MicrowaveServiceModal";
import TvServicesModal from "./TvServicesModal";
import WashingMachineServiceModal from "./WashingMachineServiceModal";
import WaterPurifierServiceModal from "./WaterPurifierServiceModal";

const AppliancesModal = () => {
  return (
    <Fragment>
      <div className="modal side_modal" id="appliancesModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                <i className="fa-solid fa-arrow-left"></i>
              </button>
              Appliances Repair Services
            </div>

            <div className="modal-body">
              <div className="service_list_content">
                <ul className="service_list">
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#tvrepair_modal"
                    >
                      <span>
                        <img src="images/television.png" alt="img" />
                      </span>
                      Television
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#wmrepair_modal"
                    >
                      <span>
                        <img src="images/washing-machine.png" alt="img" />
                      </span>
                      washing machine
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#geyserservice_modal"
                    >
                      <span>
                        <img src="images/geyser.png" alt="img" />
                      </span>
                      Geyser
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#wpservice_modal"
                    >
                      <span>
                        <img src="images/washing-machine.png" alt="img" />
                      </span>
                      water Purifier
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#mwervice_modal"
                    >
                      <span>
                        <img src="images/microwave.png" alt="img" />
                      </span>
                      microwave
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#acservice_modal"
                    >
                      <span>
                        <img src="images/air-conditioner.png" alt="img" />
                      </span>
                      Air Conditioner
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TvServicesModal />
      <WashingMachineServiceModal />
      <GeyserServiceModal />
      <WaterPurifierServiceModal />
      <MicrowaveServiceModal />
      <ACServiceModal />
    </Fragment>
  );
};

export default AppliancesModal;
