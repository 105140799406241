import React from "react";
import { useState } from "react";
import Card from "../../UI/Card";
import PackersMoversModal from "../Modals/PackersMoversModal";
import PrivateJobModal from "../Modals/PrivateJobModal";
import OverseasEducationModal from "../Modals/OverseasEducationModal";

const OpportunitieServices = (props) => {
  return (
    <>
      <div
        className="services_container col-md-6"
        id="serviceEmployementtoEmpowerment"
      >
        <div className="main_heading text-center">
          <h2>Employement to Empowerment</h2>
        </div>
        <div className="container">
          <Card>
            <div className="row">
              <div class="col-md-6">
                <a
                  class="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#privateJobsModal"
                >
                  <div class="service_icon">
                    <span class="service_ico">
                      <img src="images/jobs.png" alt="img" />
                    </span>
                  </div>

                  <div class="service_info">
                    <p>Private Jobs</p>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a
                  class="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#overseasEducationModal"
                >
                  <div class="service_icon">
                    <span class="service_ico">
                      <img src="images/education.png" alt="img" />
                    </span>
                  </div>

                  <div class="service_info">
                    <p>Overseas Education</p>
                  </div>
                </a>
              </div>
            </div>
          </Card>
        </div>
        <PrivateJobModal />
        <OverseasEducationModal />
      </div>
    </>
  );
};

export default OpportunitieServices;
