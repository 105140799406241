import React, { useState } from "react";
import {
  switchServicDetails,
  fanServicDetails,
  lightServicDetails,
} from "../../Services/ServicesList";

const ElectricianDetailModal = (props) => {
  const { modalOptions, onCloseModal, handleRequestQuote } = props;
  const [selectedId, setSelectedId] = useState();
  const closeModal = () => {
    onCloseModal();
  };

  const viewDetails = (showDetailsID) => {
    setSelectedId(showDetailsID);
  };

  return (
    <div
      className={`modal centered_Modal detailedModal selection_modal detailed_modal ${
        modalOptions === true ? "show" : ""
      }`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Electricial Services
            <button
              type="button"
              className="close modal_close_btn"
              onClick={closeModal}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div
            className="modal-body"
            data-spy="scroll"
            data-target="#myScrollspy"
            data-offset="1"
          >
            <div className="service_details_s">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="single_service_tabs">
                      <nav className="sservice_tabs_ul" id="service_scrollspy">
                        <ul className="nav">
                          <li className="nav-item">
                            <a className="nav-link active" href="#Switch">
                              Switch & socket
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#Fan">
                              Fan
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#Light">
                              Light
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="service_tab_scroll">
                      <div id="Switch" className="single_service_tab_scroll">
                        <h2>Switch & Socket</h2>
                        {switchServicDetails &&
                          switchServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                    <p className="service_duration">
                                      <span>
                                        <img
                                          src="images/duration.png"
                                          alt="img"
                                        />
                                      </span>
                                      {typeDetails.duration}
                                    </p>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Switch & Socket",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Switch & Socket",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      <div id="Fan" className="single_service_tab_scroll">
                        <h2>Fan</h2>
                        {fanServicDetails &&
                          fanServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                    <p className="service_duration">
                                      <span>
                                        <img
                                          src="images/duration.png"
                                          alt="img"
                                        />
                                      </span>
                                      {typeDetails.duration}
                                    </p>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Fan",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Fan",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      <div id="Fan" className="single_service_tab_scroll">
                        <h2>Light</h2>
                        {lightServicDetails &&
                          lightServicDetails.map((typeDetails) => (
                            <div
                              className="one_service_div"
                              key={typeDetails._id}
                            >
                              <div className="one_service_img">
                                <img
                                  src={`images/${typeDetails.propertyImg}`}
                                  alt="img"
                                />
                              </div>
                              <div className="one_service_info">
                                <div className="service_flex">
                                  <div className="one_service_det">
                                    <h5>{typeDetails.propertyType}</h5>
                                    <p className="service_duration">
                                      <span>
                                        <img
                                          src="images/duration.png"
                                          alt="img"
                                        />
                                      </span>
                                      {typeDetails.duration}
                                    </p>
                                  </div>
                                  <div className="one_service_btn">
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Light",
                                          "whatsApp"
                                        )
                                      }
                                    >
                                      <img
                                        src="images/whatsapp.png"
                                        alt="img"
                                      />
                                      request By whatsapp
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-black btn-sm"
                                      onClick={() =>
                                        handleRequestQuote(
                                          `${typeDetails.propertyType}`,
                                          "Light",
                                          "eMail"
                                        )
                                      }
                                    >
                                      <img src="images/gmail.png" alt="img" />
                                      request By Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <ul className="one_service_listing">
                                <li>{typeDetails.propertyServiceList}</li>
                                <li>
                                  <a
                                    href="#"
                                    className="view_details_btn"
                                    onClick={() =>
                                      viewDetails(`${typeDetails._id}`)
                                    }
                                  >
                                    View Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {switchServicDetails &&
                      switchServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    {fanServicDetails &&
                      fanServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    {lightServicDetails &&
                      lightServicDetails.map((fullDetails) => (
                        <div
                          className={`one_service_full_info ${
                            selectedId === `${fullDetails._id}` ? "show" : ""
                          }`}
                          key={fullDetails._id}
                        >
                          <div className="os_img">
                            <img
                              src={`images/${fullDetails.propertyImg}`}
                              alt="img"
                            />
                          </div>
                          <div className="one_serv_full_details">
                            <p>{fullDetails.propertyServiceDescription}</p>
                            <div className="included_serv">
                              <h4>Included</h4>
                              <ul>
                                {fullDetails.serviceIncludes &&
                                  fullDetails.serviceIncludes.map(
                                    (includes, i) => (
                                      <li
                                        className="single_included_serv"
                                        key={i}
                                      >
                                        <h6>{includes.heading}</h6>
                                        <p>{includes.desc}</p>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricianDetailModal;
