import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const Date = (props) => {
  const { label, name, value, className } = props;
  return (
    <div className={`form-group form-field ${className}`}>
      <label className="form_label" htmlFor={name}>
        <span>{label}</span>
      </label>
      <Field
        type="date"
        name={name}
        className={`form-control ${className}`}
        placeholder={label}
        value={value}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Date;
