import React from "react";
import { Locations } from "../Common/InitialValues";

const Banner = (props) => {
  const { page } = props;
  return (
    <section className={`banner_section ${page}`}>
      <div className="banner_content">
        <div
          className={`banner_breadcrumbs ${
            page === "CorporateServices" ? "d-none" : ""
          }`}
        >
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.php">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Location
              </li>
            </ol>
          </nav>
        </div>
        <div className="banner_info">
          <h1>
            Your Online Service Partner
            <br /> On Demand.
          </h1>
          <div
            className={`banner_inputs ${
              page === "CorporateServices" ? "d-none" : ""
            }`}
          >
            <form className="banner_form">
              <div className="form-group location_input">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src="../images/location_icon.png" alt="img" />
                    </span>
                  </div>
                  <select className="form-control">
                    <option>Location</option>
                    {Locations &&
                      Locations.map((location) => (
                        <option key={location._id} value={location.label}>
                          {location.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group service_input">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src="images/search_icon.png" alt="img" />
                    </span>
                  </div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search for services"
                  />
                </div>
                <ul className="recent_searches">
                  <li>
                    <a href="#">Rentals</a>
                  </li>
                  <li>
                    <a href="#">Packers & Movers</a>
                  </li>
                  <li>
                    <a href="#">Promotion Services</a>
                  </li>
                  <li>etc</li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
