import PestControlDetailsModal from "./PestControlDetailsModal";
import { Formik } from "formik";
import React, { useState } from "react";
import FormikControl from "../Common/Formik/FormikControl";
import { Locations, pestControlServiceValues } from "../Common/InitialValues";
import { pestControlServicesValidation } from "../Common/Validations";
import EnquiryMessage from "../../Utils/EnquiryMessage";

// componentDidMount = () => {
//   const script = document.createElement("script");

//   script.src = "https://use.typekit.net/foobar.js";
//   script.async = true;

//   document.body.appendChild(script);
// }

const PestControlServiceModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [selectedProperty, setSelectedProperty] = useState("");
  const [allValues, setAllValues] = useState({});

  const handleRequestQuote = (selectedProperties, requestFrom) => {
    setSelectedProperty(selectedProperty);
    allValues.propertyType = selectedProperties;
    const { fullName, mobileNo, emailAddress } = allValues;
    const { pincode, address, area, landmark, city, Location } = allValues;
    const user_address = `H.No/Apartment Name : ${address} - landmark : ${landmark} - area : ${area} - city : ${city} - Location : ${Location} - Pincode : ${pincode}`;
    const user_enquiry = `${allValues.propertyType} -\n Service Type- ${allValues.pestService}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_address) whatsAppText += "Address: " + user_address + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_address,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  const closeDetailedModal = () => {
    setShowModal(false);
  };

  const handleSubmitRegister = (values) => {
    setAllValues(values);
    setShowModal(true);
    setSelectedService(values);
  };
  return (
    <div
      className="modal centered_Modal selection_modal pest_control_modal"
      id="pestControlModal"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Pest Control Service
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={pestControlServiceValues}
                validationSchema={pestControlServicesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="pincode"
                            label="Pincode"
                            onChange={handleChange}
                            placeholder="6 digits [0-9] PIN code"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="address"
                            label="Address"
                            onChange={handleChange}
                            placeholder="Flat, House no., Building, Company, Apartment"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="area"
                            label="Area, Street, Village"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="landmark"
                            label="Landmark"
                            onChange={handleChange}
                            placeholder="E.g. near apollo hospital"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="city"
                            label="Town/City"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="select"
                            label="Your Location"
                            name="Location"
                            onChange={handleChange}
                            options={Locations}
                          />
                        </div>
                      </div>
                      <FormikControl
                        control="input"
                        type="radio"
                        label="Please Select"
                        name="pestService"
                        className="pest_service"
                        onChange={handleChange}
                        options={[
                          { _id: 0, label: "Cockroach & Ant Control" },
                          { _id: 1, label: "Bedbugs Control" },
                          { _id: 2, label: "Termite Control" },
                          { _id: 3, label: "Mosquito Control" },
                        ]}
                      />

                      <div className="form-group-btn">
                        <button type="submit" className="btn btn-black w-100">
                          Continue
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
      <PestControlDetailsModal
        modalOptions={showModal}
        selectedService={selectedService}
        onCloseModal={closeDetailedModal}
        handleRequestQuote={handleRequestQuote}
      />
    </div>
  );
};

export default PestControlServiceModal;
