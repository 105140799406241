import React, { useState } from 'react';
import FormikControl from "../../../Common/Formik/FormikControl";
import {AddFarmlandValues} from "../../../Common/InitialValues";
import {AddFarmlandValiation} from "../../../Common/Validations";
import { Formik } from "formik";
import "./AddAsset.css";

const AddFarmland = (props) => {

  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values) => {
    console.log(values);
  };
  
  return (
     <div className='add_asset_div'>
        <Formik
                initialValues={AddFarmlandValues}
                validationSchema={AddFarmlandValiation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values, requestType);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit, values } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                      <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="Name"
                            label="Name"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6 pr-0 pl-1">
                          <FormikControl
                            control="input"
                            type="email"
                            name="Email"
                            label="Email"
                            onChange={handleChange}
                          />
                        </div>
                        
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="tel"
                            name="Primarycontact"
                            label="Primary Contact"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6 pr-0 pl-1">
                          <FormikControl
                            control="input"
                            type="tel"
                            name="Secondarycontact"
                            label="Secondary Contact"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="url"
                            name="website"
                            label="Website"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pr-0 pl-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="Location"
                            label="Location"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="title"
                            label="Title"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pl-1 pr-0">
                          <FormikControl
                            control="input"
                            type="text"
                            name="Price"
                            label="Price"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12 pr-0 pl-0">
                          <FormikControl
                            control="input"
                            type="textarea"
                            name="Description"
                            label="Description"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="file"
                            name="Farmlandpictures"
                            label="Farmland Pictures"
                            onChange={handleChange}
                          />
                          <p className='input_instruction'>**You can upload upto 12 Images and each size less than 1MB.</p>
                        </div>
                        <div className="col-md-12 pr-0 pl-0">
                          <FormikControl
                            control="input"
                            type="textarea"
                            name="Farmlanddetails"
                            label="Farmland Details"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12 pr-0 pl-0">
                          <FormikControl
                            control="input"
                            type="textarea"
                            name="Neighbourhood"
                            label="Neighbourhood"
                            onChange={handleChange}
                          />
                        </div>

                      </div>



                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
     </div>
  )
}

export default AddFarmland