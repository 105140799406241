import React, { useState } from 'react';
import "./Admin.css";
import Breadcrumbs from './Components/Breadcrumbs';
import PropertyTypeTabs from './Components/PropertyTypeTabs';

const Admin = () => {

  const [crumbs,setCrumbs] = useState(['home','add aroperty']);


  return (
    <div className='admin_page'>
       <Breadcrumbs crumbs={crumbs} />
       <div className='container'>
          <PropertyTypeTabs />
       </div>
    </div>
  )
}

export default Admin