import { Formik } from "formik";
import React, { useState } from "react";
import EnquiryMessage from "../../Utils/EnquiryMessage";
import FormikControl from "../Common/Formik/FormikControl";
import {
  shiftingServiceValues,
  Locations,
  DesignServiceValues,
} from "../Common/InitialValues";
import {
  DesignServiceValidation,
  shiftingServiceValidation,
} from "../Common/Validations";

const DesignModal = (props) => {
  const { clickedService } = props;
  const [requestType, setRequestType] = useState();
  const [showOther, setShowOther] = useState(false);
  const HandleShowOther = (e) => {
    if (e.target.value == "Others") {
      setShowOther(true);
    }
  };

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedServiceName, requestFrom) => {
    const { fullName, emailAddress, mobileNo, typeOfSite, otherType } = values;
    const user_enquiry = `${selectedServiceName} - Type : ${typeOfSite} ${
      otherType ? `- ${otherType}` : ""
    }`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <div
      className="modal centered_Modal selection_modal"
      id={`design${clickedService.id}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {clickedService.serviceName}
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={DesignServiceValues}
                validationSchema={DesignServiceValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(
                    values,
                    clickedService.serviceName,
                    requestType
                  );
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit, values } = formik;
                  return (
                    <form
                      className="property_selection_form property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <FormikControl
                        control="input"
                        type="checkbox"
                        label={
                          clickedService.serviceName ==
                          "website Design Services"
                            ? "Type Of Site"
                            : "Type Of App"
                        }
                        name="typeOfSite"
                        className="pest_service"
                        onChange={(e) => (handleChange(e), HandleShowOther(e))}
                        options={
                          clickedService.serviceName ==
                          "website Design Services"
                            ? [
                                { _id: "aspc0", label: "Business Website" },
                                { _id: "aspc1", label: "eCommerce Website" },
                                { _id: "aspc2", label: "Blog Website" },
                                { _id: "aspc3", label: "Portfolio Website" },
                                { _id: "aspc4", label: "Membership Website" },
                                { _id: "aspc5", label: "Nonprofit Website" },
                                { _id: "aspc6", label: "Personal Website" },
                                { _id: "aspc7", label: "News Websites" },
                                { _id: "aspc8", label: "Education website" },
                                { _id: "aspc9", label: "Others" },
                              ]
                            : [
                                { _id: "aspc0", label: "Native App" },
                                { _id: "aspc1", label: "mobile Web App" },
                                { _id: "aspc2", label: "progressive Web App" },
                                { _id: "aspc3", label: "Hybrid App" },
                                {
                                  _id: "aspc4",
                                  label: "Cross-platform Application",
                                },
                                { _id: "aspc9", label: "Others" },
                              ]
                        }
                      />
                      {showOther == true ? (
                        <div className="col-md-12">
                          <FormikControl
                            control="input"
                            type="text"
                            name="otherType"
                            label="Others"
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignModal;
