import React from "react";
import Card from "../../UI/Card";
import ApartmentRentalModal from "../Modals/ApartmentRentalModal";
import OfficesRentalModal from "../Modals/OfficesRentalModal";
import IndividualHousesRentalModal from "../Modals/IndividualHousesRentalModal";
import CommercialRentalModal from "../Modals/CommercialRentalModal";

const RentalServices = (props) => {
  return (
    <div className="services_container col-md-12" id="serviceRentalServices">
      <div className="main_heading text-center">
        <h2>Rental Services</h2>
      </div>
      <div className="container">
        <Card>
          <div className="row">
            <div className="col-md-3">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#apartments_rental_Modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/real-estate.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Apartments</p>
                </div>
              </a>
            </div>
            <div className="col-md-3">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#offices_rental_Modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/office-building.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Offices</p>
                </div>
              </a>
            </div>
            <div className="col-md-3">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#individual_rental_Modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/home.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Individual Houses</p>
                </div>
              </a>
            </div>
            <div className="col-md-3">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#commercial_rental_Modal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/shop.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>commercial properties</p>
                </div>
              </a>
            </div>
          </div>
        </Card>
      </div>
      <ApartmentRentalModal />
      <OfficesRentalModal />
      <IndividualHousesRentalModal />
      <CommercialRentalModal />
    </div>
  );
};

export default RentalServices;
