import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const CustomCheckbox = (props) => {
  const { name, options, label,onChange, ...rest } = props;
  return (
    <div className={`form-group form-field ${name}`}>
      <label className="form_label" htmlFor={name}>
        {label}
      </label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <label className="check_container" key={option._id}>
                {option.label}
                <input
                  type="checkbox"
                  onChange={onChange}
                  name={name}
                  value={option.label}
                />
                <span className="checkmark"></span>
              </label>
            );
          });
        }}
      </Field>

      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default CustomCheckbox;
