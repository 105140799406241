import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const InstructionsRadio = (props) => {
  const { name, options, label, className, selectedValue, ...rest } = props;
  const selectedRadio = selectedValue.toString();
  return (
    <div className={`form-group form-field ${className}`}>
      <label className="form_label" htmlFor={name}>
        {label}
      </label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option._id}>
                <label className="select_container">
                  {option.label}
                  <input
                    type="radio"
                    {...field}
                    name={name}
                    value={option.label}
                  />
                  <span className="checkmark"></span>
                </label>
                <div
                  className={`shifting_items_info ${
                    selectedRadio === option.label ? "" : "d-none"
                  }`}
                >
                  {selectedRadio === "1RK" ? (
                    <div>
                      <h6>10 Items Approx</h6>
                      <ul>
                        <li>1 Cot with Matress</li>
                        <li>2 Chairs</li>
                        <li>1 Fridge</li>
                        <li>1 Washing Machine</li>
                        <li>1 Gas Stove</li>
                        <li>1 Cylinder</li>
                        <li>1 Water Purifier</li>
                        <li>1 TV</li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedRadio === "1BHK" ? (
                    <div>
                      <h6>16 Items Approx</h6>
                      <ul>
                        <li>2 Cot with Matress</li>
                        <li>2 Almaraih</li>
                        <li>4 Chairs</li>
                        <li>1 Fridge</li>
                        <li>1 Washing Machine</li>
                        <li>1 Gas Stove</li>
                        <li>1 Cylinder</li>
                        <li>1 Water Purifier</li>
                        <li>1 TV</li>
                        <li>1 Tv Stand/Study table</li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedRadio === "2BHK" ? (
                    <div>
                      <h6>25 Items Approx</h6>
                      <ul>
                        <li>2 Cot with Matress</li>
                        <li>1 Almaraih</li>
                        <li>4 Chairs</li>
                        <li>1 Sofa</li>
                        <li>1 Center Table</li>
                        <li>1 Dining Table(4 Seater)</li>
                        <li>1 Shoe Rack</li>
                        <li>1 Fridge</li>
                        <li>1 Washing Machine</li>
                        <li>1 Gas Stove</li>
                        <li>2 Cylinders</li>
                        <li>1 Water Purifier</li>
                        <li>1 TV</li>
                        <li>1 Tv Stand/Study table</li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedRadio === "3BHK" ? (
                    <div>
                      <h6>25 Items Approx</h6>
                      <ul>
                        <li>2 Cot with Matress</li>
                        <li>1 Almaraih</li>
                        <li>4 Chairs</li>
                        <li>1 Sofa</li>
                        <li>1 Center Table</li>
                        <li>1 Dining Table(4 Seater)</li>
                        <li>1 Shoe Rack</li>
                        <li>1 Fridge</li>
                        <li>1 Washing Machine</li>
                        <li>1 Gas Stove</li>
                        <li>2 Cylinders</li>
                        <li>1 Water Purifier</li>
                        <li>1 TV</li>
                        <li>1 Tv Stand/Study table</li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedRadio === "4BHK" ? (
                    <div>
                      <h6>25 Items Approx</h6>
                      <ul>
                        <li>2 Cot with Matress</li>
                        <li>1 Almaraih</li>
                        <li>4 Chairs</li>
                        <li>1 Sofa</li>
                        <li>1 Center Table</li>
                        <li>1 Dining Table(4 Seater)</li>
                        <li>1 Shoe Rack</li>
                        <li>1 Fridge</li>
                        <li>1 Washing Machine</li>
                        <li>1 Gas Stove</li>
                        <li>2 Cylinders</li>
                        <li>1 Water Purifier</li>
                        <li>1 TV</li>
                        <li>1 Tv Stand/Study table</li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            );
          });
        }}
      </Field>

      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default InstructionsRadio;
