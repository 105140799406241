import React from "react";
import Card from "../../UI/Card";
import HomeCleaningModal from "../Modals/HomeCleaningModal";
import Section from "../Section";
import ElectricianServiceModal from "../Modals/ElectricianServiceModal";
import PaintingServiceModal from "../Modals/PaintingServiceModal";
import PlumbingServiceModal from "../Modals/PlumbingServiceModal";
import PestControlServiceModal from "../Modals/PestControlServiceModal";
import CarpenterModal from "../Modals/CarpenterModal";
import AppliancesModal from "../Modals/AppliancesModal";
import MobileServiceModal from "../Modals/MobileServiceModal";

const HomeServices = (props) => {
  return (
    <div className="home_services col-md-6" id="serviceHomeservice">
      <div className="main_heading text-center">
        <h2>Home Services</h2>
      </div>
      <div className="container">
        <Card>
          <div className="row">
            <div className="col-md-custom-7">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#homeCleaningModal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/housekeeping.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Home Cleaning</p>
                </div>
              </a>
            </div>
            <div className="col-md-custom-7">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#homeCleaningModal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/housekeeping.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Office Cleaning</p>
                </div>
              </a>
            </div>

            {/* <div className="col-md-custom-7">
              <a
                className="single_service"
                href="#"
                data-toggle="modal"
                data-target="#mobileServiceModal"
              >
                <div className="service_icon">
                  <span className="service_ico">
                    <img src="images/mobile-repair.png" alt="img" />
                  </span>
                </div>
                <div className="service_info">
                  <p>Mobile Repair</p>
                </div>
              </a>
            </div> */}
          </div>
        </Card>
      </div>
      <PestControlServiceModal />
      <HomeCleaningModal />
      <CarpenterModal />
      <ElectricianServiceModal />
      <PaintingServiceModal />
      <PlumbingServiceModal />
      <AppliancesModal />
      <MobileServiceModal />
    </div>
  );
};

export default HomeServices;
