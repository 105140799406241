import * as Yup from "yup";

const openPlotValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
});

const flatValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),

  propertyStatus: Yup.string().required("At least one checkbox is required"),
  propertySize: Yup.array().min(1, "Please select Atleast one value"),
  priceRangeCheck: Yup.array().min(1, "Please select Atleast one value"),
});

const individualHousesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),

  propertyStatus: Yup.string().required("At least one checkbox is required"),
  propertySize: Yup.array().min(1, "Please select Atleast one value"),
  priceRangeCheck: Yup.array().min(1, "Please select Atleast one value"),
});

const farmLandValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Email Address Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
});

const rentalServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  propertySize: Yup.array().min(1, "Please select Atleast one value"),
  priceRangeCheck: Yup.array().min(1, "Please select Atleast one value"),
  propertyStatus: Yup.string().required("Please select Atleast one value"),
});

const commercialRentalServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  rentalPurpose: Yup.string().required("Rental Purpose is Required"),
  rentRangeCheck: Yup.array().min(1, "Please select Atleast one value"),
  priceRangeCheck: Yup.array().min(1, "Please select Atleast one value"),
  propertyStatus: Yup.string().required("Please select Atleast one value"),
});

const shiftingServiceValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  movingFrom: Yup.string().required("Please enter Starting location"),
  movingTo: Yup.string().required("Please enter destination location"),
  shiftingDate: Yup.string().required("Please Enter a Shifting Date"),
  instructions: Yup.string().required("Please select Atleast one value"),
});

const loanServiceValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  employmentType: Yup.string().required("Please select your employment type"),
  monthlyIncome: Yup.string().required("Income is Required"),
  dateOfBirth: Yup.string().required("Please select your date of Birth"),
  residencePincode: Yup.string().required("Residence pincode is required"),
  emailAddress: Yup.string().required("email Address is required"),
});

const pestControlServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  pestService: Yup.string().required("Please select Atleast one value"),
});

const homeCleaningServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  serviceType: Yup.string().required("Please select A Service"),
});

const carpenterServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
});

const electricianServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
});

const paintingServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
});

const plumbingServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
});

const TVServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  tvService: Yup.string().required("Please select A Service"),
});

const WashingMachineServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  wmService: Yup.string().required("Please select A Service"),
});

const GeyserServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  geyserService: Yup.string().required("Please select A Service"),
});

const WPServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  Service: Yup.string().required("Please select A Service"),
});

const MWServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  Service: Yup.string().required("Please select A Service"),
});

const ACServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  Service: Yup.string().required("Please select A Service"),
});

const MobileServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  Service: Yup.string().required("Please select A Service"),
});

const LEDRentalServicesValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfEvent: Yup.array().min(1, "Please select Atleast one value"),
  eventSpace: Yup.string().required("Rental Purpose is Required"),
});

const AutoCampaignServiceValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfCampaign: Yup.array().min(1, "Please select Atleast one value"),
});
const LeafletDistributionServiceValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfCampaign: Yup.array().min(1, "Please select Atleast one value"),
});

const BannerPastingServiceValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfCampaign: Yup.array().min(1, "Please select Atleast one value"),
});

const DigitalMarketingServiceValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfCampaign: Yup.array().min(1, "Please select Atleast one value"),
});

const OutdoorMarketingServiceValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please select a location"),
  purposeOfCampaign: Yup.array().min(1, "Please select Atleast one value"),
});

const AddFlatValiation = Yup.object().shape({
  Name: Yup.string().required("Name is Required"),
  Email: Yup.string().required("email Address is required"),
  Primarycontact: Yup.string()
    .required("Contact Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please Enter Location Details"),
  Flatpictures: Yup.array().required("Flat Pictures are required"),
  Flatdetails: Yup.string().required("Flat details are Required"),
});

const AddPlotValiation = Yup.object().shape({
  Name: Yup.string().required("Name is Required"),
  Email: Yup.string().required("email Address is required"),
  Primarycontact: Yup.string()
    .required("Contact Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please Enter Location Details"),
  PlotPictures: Yup.string().required("Plot Pictures are required"),
  PlotDetails: Yup.string().required("Plot details are Required"),
});

const AddFarmlandValiation = Yup.object().shape({
  Name: Yup.string().required("Name is Required"),
  Email: Yup.string().required("email Address is required"),
  Primarycontact: Yup.string()
    .required("Contact Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  Location: Yup.string().required("Please Enter Location Details"),
  Farmlandpictures: Yup.string().required("Farmland Pictures are required"),
  Farmlanddetails: Yup.string().required("Farmland details are Required"),
});

const DeliveryServiceValiation = Yup.object().shape({
  Name: Yup.string().required("Name is Required"),
  emailAddress: Yup.string().required("email Address is required"),
  mobileNumber: Yup.string()
    .required("Contact Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("area is Required"),
  landmark: Yup.string().required("landmark is Required"),
  city: Yup.string().required("city is Required"),
  Location: Yup.string().required("Please select a location"),
  orderDetails: Yup.string().required("Order Details are required"),
});

const DesignServiceValidation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  emailAddress: Yup.string().required("email Address is required"),
  typeOfSite: Yup.array().min(1, "Please select Atleast one value"),
  otherType: Yup.string(),
});

const PrivateJobsValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  emailAddress: Yup.string().required("email Address is required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  profession: Yup.string().required("Profession is required"),
  cityPreference: Yup.string().required("City Preference is required"),
  preferedSal: Yup.number()
    .typeError("Please enter valid number")
    .required("Prefered Salary is required"),
  jobLooking: Yup.array().min(1, "Please select Atleast one value"),
});

const OverseasEducationValiation = Yup.object().shape({
  fullName: Yup.string().required("Fullname is Required"),
  emailAddress: Yup.string().required("email Address is required"),
  mobileNo: Yup.string()
    .required("Mobile Number Required")
    .min(10, "Length must be 10 characters")
    .max(10, "Length must be 10 characters"),
  coursePrefered: Yup.string().required("Profession is required"),
  cityPreference: Yup.string().required("Country Preference is required"),
});

export {
  openPlotValidation,
  flatValidation,
  individualHousesValidation,
  farmLandValidation,
  rentalServicesValidation,
  pestControlServicesValidation,
  commercialRentalServicesValidation,
  shiftingServiceValidation,
  loanServiceValidation,
  homeCleaningServicesValidation,
  carpenterServicesValidation,
  electricianServicesValidation,
  paintingServicesValidation,
  plumbingServicesValidation,
  TVServicesValidation,
  WashingMachineServicesValidation,
  GeyserServicesValidation,
  WPServicesValidation,
  MWServicesValidation,
  ACServicesValidation,
  MobileServicesValidation,
  LEDRentalServicesValidation,
  AutoCampaignServiceValiation,
  LeafletDistributionServiceValiation,
  BannerPastingServiceValiation,
  DigitalMarketingServiceValiation,
  AddFlatValiation,
  AddPlotValiation,
  AddFarmlandValiation,
  DeliveryServiceValiation,
  DesignServiceValidation,
  PrivateJobsValiation,
  OverseasEducationValiation,
  OutdoorMarketingServiceValiation,
};
