import React, { useState } from "react";
import { Formik } from "formik";
import FormikControl from "../Common/Formik/FormikControl";
import { Locations, LEDRentalServiceValues } from "../Common/InitialValues";
import { LEDRentalServicesValidation } from "../Common/Validations";
import EnquiryMessage from "../../Utils/EnquiryMessage";

const LEDRentalServiceModal = () => {
  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, rentalType, requestFrom) => {
    const { Location, purposeOfEvent, eventSpace, startDate, endDate } = values;
    const { fullName, mobileNo, emailAddress } = values;
    const user_enquiry = `Rental_type : ${rentalType} - Rental Location : ${Location} - Purpose Of Event : ${purposeOfEvent} - Event Space Type : ${eventSpace} - Event Start Date : ${startDate} - Event Space Type : ${eventSpace} - Event End Date : ${endDate} `;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <div className="modal centered_Modal selection_modal" id="LED_rental_Modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            LED Rental Service
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={LEDRentalServiceValues}
                validationSchema={LEDRentalServicesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values, "LED", requestType);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-0">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-1 pr-0">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="select"
                            label="Your Location"
                            name="Location"
                            onChange={handleChange}
                            options={Locations}
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Purpose Of Event"
                        name="purposeOfEvent"
                        onChange={handleChange}
                        options={[
                          { _id: "poe0", label: "Marriage/Family Function" },
                          { _id: "poe1", label: "Political Rally" },
                          { _id: "poe2", label: "Corporate Event" },
                          { _id: "poe3", label: "Live Shows" },
                        ]}
                      />

                      <FormikControl
                        control="input"
                        type="radio"
                        label="Type of Space"
                        name="eventSpace"
                        className="property_status"
                        onChange={handleChange}
                        options={[
                          { _id: "es0", label: "Indoor" },
                          { _id: "es1", label: "Outdoor" },
                        ]}
                      />

                      <div className="row">
                        <div className="col-md-6 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="date"
                            name="startDate"
                            label="Start Date"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 pl-1 pr-0">
                          <FormikControl
                            control="input"
                            type="date"
                            name="endDate"
                            label="End Date"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LEDRentalServiceModal;
