import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

const ChatPopup = () => {
  const whatsAppBusinessNum = +918712355455;
  const steps = [
    {
      id: "1",

      // This message appears in
      // the bot chat bubble
      message: "Hi, How can i help you?",
      trigger: "2",
    },
    {
      id: "2",

      // Here we want the user
      // to enter input
      user: true,
      trigger: "3",
    },
    {
      id: "3",
      message: ({ previousValue, steps }) =>
        "please contact with us in whatsapp.",
      trigger: (value, steps) =>
        window.open(
          `http://api.whatsapp.com/send?phone=${whatsAppBusinessNum}&text=hi`,
          "_blank"
        ),
    },
  ];

  // Creating our own theme
  const theme = {
    background: "#FCE15A",
    headerBgColor: "#000000",
    headerFontSize: "20px",
    botBubbleColor: "#000000",
    headerFontColor: "#ffffff",
    botFontColor: "white",
    userBubbleColor: "#000000",
    userFontColor: "white",
  };

  // Set some properties of the bot
  const config = {
    botAvatar: "images/profile-user.png",
    floating: true,
  };

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        // This appears as the header
        // text for the chat bot
        headerTitle="Chat"
        steps={steps}
        {...config}
      />
    </ThemeProvider>
  );
};

export default ChatPopup;
