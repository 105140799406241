import React from "react";
import Banner from "./Banner";
import "./Home.css";
import {
  MainServicesArray,
  AssetValidationArray,
  RentalServicesArray,
  PackersMoversArray,
  LoanServicesArray,
  designServiceArray,
} from "../../Services/ServicesList";
import Services from "./Services";
import AssetValidation from "./AssetValidation";
import RentalServices from "./RentalServices";
import Section from "../Section";
import PackersMoversServices from "./PackersMoversSecrvices";
import LoanServices from "./LoanServices";
import HomeServices from "./HomeServices";
import AdvertisingServices from "./AdvertisingServices";
import MobileLaptopServices from "./MobileLaptopServices";
import MobilesForSale from "./MobilesForSale";
import { MobilesForSaleValues } from "../Common/InitialValues";
import DeliveryServices from "./DeliveryServices";
import OpportunitieServices from "./OpportunitieServices";
import DesignServices from "./DesignServices";
const Home = () => {
  return (
    <div className="home-page">
      <Banner page="homePage" />
      <Services ServicesList={MainServicesArray} />
      <DeliveryServices />
      {/* <MobilesForSale saleList={MobilesForSaleValues} /> */}
      <Section className="rental_packers" id="rental_packers">
        {/* <MobileLaptopServices /> */}
        <div className="container">
          <div className="row">
            <HomeServices />
            <PackersMoversServices ServicesList={PackersMoversArray} />
          </div>
        </div>
      </Section>
      <LoanServices ServicesList={LoanServicesArray} />

      <AdvertisingServices />
      <Section className="rental_packers" id="rental_packers">
        {/* <MobileLaptopServices /> */}
        <div className="container">
          <div className="row">
            <RentalServices ServicesList={RentalServicesArray} />
          </div>
        </div>
      </Section>

      <AssetValidation ServicesList={AssetValidationArray} />
      <Section className="rental_packers mb-3" id="rental_packers">
        <div className="container">
          <div className="row">
            <OpportunitieServices ServicesList={PackersMoversArray} />
            <DesignServices ServicesList={designServiceArray} />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;
