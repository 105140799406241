import React from "react";
import { useState } from "react";
import Card from "../../UI/Card";
import PackersMoversModal from "../Modals/PackersMoversModal";

const PackersMoversServices = (props) => {
  return (
    <div className="services_container col-md-6" id="servicePackersMovers">
      <div className="main_heading text-center">
        <h2>Packers & Movers</h2>
      </div>
      <div className="container">
        <Card>
          <div className="row">
            {props.ServicesList &&
              props.ServicesList.map((service, i) => (
                <div className="col-md-6" key={i}>
                  <a
                    className="single_service"
                    href="#"
                    data-toggle="modal"
                    data-target={`#packer${service.id}`}
                  >
                    <div className="service_icon">
                      <span className="service_ico">
                        <img src={`images/${service.serviceIcon}`} alt="img" />
                      </span>
                    </div>
                    <div className="service_info">
                      <p>{service.serviceName}</p>
                    </div>
                  </a>
                  <PackersMoversModal clickedService={service} />
                </div>
              ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PackersMoversServices;
