import { v4 as uuid } from "uuid";

const baseURL = "https://www.eesy.in/";

const Locations = [
  { _id: "L0", label: "Adilabad" },
  { _id: "L1", label: "Bhadradri Kothagudem" },
  { _id: "L2", label: "Hanumakonda" },
  { _id: "L3", label: "Hyderabad" },
  { _id: "L4", label: "Jagtial" },
  { _id: "L5", label: "Jangaon" },
  { _id: "L6", label: "Jayashankar Bhoopalpally" },
  { _id: "L7", label: "Jogulamba Gadwal" },
  { _id: "L8", label: "Kamareddy" },
  { _id: "L9", label: "Karimnagar" },
  { _id: "L10", label: "Khammam" },
  { _id: "L11", label: "Komaram Bheem Asifabad" },
  { _id: "L12", label: "Mahabubabad" },
  { _id: "L13", label: "Mahabubnagar" },
  { _id: "L14", label: "Mancherial" },
  { _id: "L15", label: "Medak" },
  { _id: "L16", label: "Medchal" },
  { _id: "L17", label: "Mulug" },
  { _id: "L18", label: "Nagarkurnool" },
  { _id: "L19", label: "Nalgonda" },
  { _id: "L20", label: "Narayanpet" },
  { _id: "L21", label: "Nirmal" },
  { _id: "L22", label: "Nizamabad" },
  { _id: "L23", label: "Peddapalli" },
  { _id: "L24", label: "Rajanna Sircilla" },
  { _id: "L25", label: "Rangareddy" },
  { _id: "L26", label: "Sangareddy" },
  { _id: "L27", label: "Siddipet" },
  { _id: "L28", label: "Suryapet" },
  { _id: "L29", label: "Vikarabad" },
  { _id: "L30", label: "Wanaparthy" },
  { _id: "L31", label: "Warangal (Rural)" },
  { _id: "L32", label: "Warangal (Urban)" },
  { _id: "L33", label: "Yadadri Bhuvanagiri" },
];

const DoorStepDeliveryServiceValues = [
  {
    id: "DS1",
    serviceName: "Food",
    imageURL: "food.png",
  },
  {
    id: "DS2",
    serviceName: "Fruits & Vegetables",
    imageURL: "fruits.png",
  },
  {
    id: "DS3",
    serviceName: "Meat & Fish",
    imageURL: "meat.png",
  },
  {
    id: "DS4",
    serviceName: "Cake & Coke",
    imageURL: "cake-slice.png",
  },
  {
    id: "DS5",
    serviceName: "Medicine",
    imageURL: "medicine.png",
  },
  {
    id: "DS6",
    serviceName: "Grocery",
    imageURL: "grocery.png",
  },
  {
    id: "DS7",
    serviceName: "Building Materials",
    imageURL: "building-materials.png",
  },
  {
    id: "DS8",
    serviceName: "Fuel",
    imageURL: "fuel.png",
  },
  {
    id: "DS9",
    serviceName: "Courier Transport",
    imageURL: "courier.png",
  },
  {
    id: "DS9",
    serviceName: "Books & Stationery",
    imageURL: "stationary.png",
  },
];

const MobilesForSaleValues = [
  {
    id: "MS1",
    name: "SAMSUNG Galaxy F13 (Waterfall Blue, 64 GB)",
    brand: "samsung",
    price: 14999,
    discountPercentage: 20,
    bannerImage: "samsung.png",
    image: `${baseURL}/images/mobiles/samsung-f13.webp`,
  },
  {
    id: "MS2",
    name: "REDMI A1+ (Light Green, 32 GB)",
    brand: "mi",
    price: 7499,
    discountPercentage: 25,
    bannerImage: "mi.png",
    image: `${baseURL}/images/mobiles/samsung-f13.webp`,
  },
  {
    id: "MS3",
    name: "Google Pixel 6a (Charcoal, 128 GB)",
    brand: "google",
    price: 30999,
    discountPercentage: 29,
    bannerImage: "pixel6a.png",
    image: `${baseURL}/images/mobiles/samsung-f13.webp`,
  },
];

const PropertyListValues = [
  {
    id: "p1",
    Type: "villa",
    Title: "Villa on Hollywood Boulevard",
    Address: "398 Pete Pascale Pl, New York",
    Description:
      "Massa tempor nec feugiat nisl pretium. Egestas fringilla phasellus faucibus scelerisque eleifend donec. Porta nibh venenatis cras sed felis eget velit aliquet. Neque volutpat ac tincidunt vitae semper quis lectus. Turpis in eu mi bibendum neque egestas congue quisque. Sed elementum tempus egestas sed sed risus pretium quam. Dignissim sodales ut eu sem. Nibh mauris cursus mattis molestie a iaculis at erat pellentesque. Id interdum velit laoreet id donec ultrices tincidunt.",
    Images: [
      "images/Hollywood-Boulevard-img1.jpg",
      "images/Hollywood-Boulevard-img2.jpg",
      "images/Hollywood-Boulevard-img3.jpg",
      "images/Hollywood-Boulevard-img4.jpg",
      "images/Hollywood-Boulevard-img5.jpg",
      "images/Hollywood-Boulevard-img6.jpg",
    ],
    Price: "1.250.000",
    PropertyDetails: {
      Area: "2700",
      Floor: "4",
      Parking: ["Car", "Bike"],
      GatedSecurity: "Yes",
    },
    Neighbourhood: {
      Institutes: ["Frank Anthony Public School"],
      ShoppingMalls: ["M Block Market"],
      Hospitals: ["Phoenix Hospital"],
    },
    Amenities: ["Lift", "Security", "Visitor parking", "Water Storage"],
    ProjectHighlights:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  },
  {
    id: "p2",
    Type: "Apartment",
    Title: "Luxury Family Home",
    Address: "1421 San Pedro St, Los Angeles, CA 90015",
    Description:
      "Evans Tower very high demand corner junior one bedroom plus a large balcony boasting full open NYC views. You need to see the views to believe them. Mint condition with new hardwood floors. Lots of closets plus washer and dryer.Fully furnished. Elegantly appointed condominium unit situated on premier location. PS6. The wide entry hall leads to a large living room with dining area. This expansive 2 bedroom and 2 renovated marble bathroom apartment has great windows. Despite the interior views, the apartments Southern and Eastern exposures allow for lovely natural light to fill every room. The master suite is surrounded by handcrafted milkwork and features incredible walk-in closet and storage space.",
    Images: ["images/Luxury-Home-img1.jpg", "Luxury-Home-img2.jpg"],
    Price: "13,000",
    PropertyDetails: {
      Area: "5280",
      Floor: "1",
      Parking: ["Car", "Bike"],
      GatedSecurity: "Yes",
    },
    Neighbourhood: {
      Institutes: ["Frank Anthony Public School", "Wisdom School"],
      ShoppingMalls: ["M Block Market"],
      Hospitals: ["Phoenix Hospital"],
    },
    Amenities: ["Security", "Water Storage"],
    ProjectHighlights:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  },
];

const openPlotValues = {
  propertyType: "Open Plots",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  priceMinValue: "",
  priceMaxValue: "",
  areaMinValue: "",
  areaMaxValue: "",
  otherServices: [],
};

const flatApartmentValues = {
  propertyType: "Flats/Apartments",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  propertySize: [],
  propertyStatus: "",
  priceRangeCheck: [],
  otherServices: [],
};

const individualHousesValues = {
  propertyType: "Individual houses",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  propertySize: [],
  propertyStatus: "",
  priceRangeCheck: [],
  otherServices: [],
};

const farmLandValues = {
  propertyType: "Farm Lands",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  priceMinValue: "",
  priceMaxValue: "",
  areaMinValue: "",
  areaMaxValue: "",
  otherServices: [],
};

const rentalServicesValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  propertySize: [],
  priceRangeCheck: [],
  propertyStatus: "",
  otherServices: [],
};

const commercialRentalServicesValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  rentalPurpose: "",
  rentRangeCheck: [],
  areaMinValue: "",
  areaMaxValue: "",
  propertyStatus: "",
};

const shiftingServicesValues = {
  City: "",
  fullName: "",
  mobileNo: "",
  movingFrom: "",
  movingTo: "",
  shiftingDate: "",
  shiftingDetails: "",
};

const shiftingServiceValues = {
  fullName: "",
  mobileNo: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Location: "",
  movingFrom: "",
  movingTo: "",
  shiftingDate: "",
  instructions: "",
};

const loanServiceValues = {
  fullName: "",
  dateOfBirth: "",
  mobileNo: "",
  employmentType: "",
  companyName: "",
  monthlyIncome: "",
  residencePincode: "",
  emailAddress: "",
};

const pestControlServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  pestService: "",
  propertyType: "",
};

const homeCleaningServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  serviceType: "",
  propertyType: "",
};

const carpenterServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  propertyType: "",
};

const electricianServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  propertyType: "",
};

const paintingServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  propertyType: "",
};

const plumbingServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  propertyType: "",
};

const TvServiceValues = {
  applianceType: "Television",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  brandName: "",
  tvService: "",
};

const WashingMachineServiceValues = {
  applianceType: "Washing Machine",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  brandName: "",
  wmService: "",
};

const GeyserServiceValues = {
  applianceType: "Geyser",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  brandName: "",
  geyserService: "",
};

const WaterPurifierServiceValues = {
  applianceType: "Water Purifier",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  brandName: "",
  Service: "",
};

const MWServiceValues = {
  applianceType: "Micro Wave",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Locations: "",
  brandName: "",
  Service: "",
};

const ACServiceValues = {
  applianceType: "AirConditioner",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Location: "",
  brandName: "",
  Service: "",
};

const MobileServiceValues = {
  applianceType: "",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Location: "",
  brandName: "",
  model: "",
  Service: "",
};

const LEDRentalServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  purposeOfEvent: [],
  eventSpace: "",
  startDate: "",
  endDate: "",
};

const AutoCampaignValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  typeOfCampaign: "",
  purposeOfCampaign: [],
  startDate: "",
  endDate: "",
};

const LeafletDistributionValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  purposeOfCampaign: [],
  startDate: "",
  endDate: "",
};

const BannerPastingValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  purposeOfCampaign: [],
  startDate: "",
  endDate: "",
};

const DigitalMarketingValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  purposeOfCampaign: [],
};

const OutdoorMarketingValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  Location: "",
  purposeOfCampaign: [],
};

const AddFlatValues = {
  Name: "",
  Email: "",
  Primarycontact: "",
  Secondarycontact: "",
  Website: "",
  Location: "",
  Title: "",
  Price: "",
  Description: "",
  Flatpictures: [],
  Flatdetails: "",
  Neighbourhood: "",
  Amenitiesdetails: "",
  Projecthighlights: "",
};

const AddPlotValues = {
  Name: "",
  Email: "",
  Primarycontact: "",
  Secondarycontact: "",
  Website: "",
  Location: "",
  Title: "",
  Price: "",
  Description: "",
  PlotPictures: "",
  PlotDetails: "",
  Neighbourhood: "",
};

const AddFarmlandValues = {
  Name: "",
  Email: "",
  Primarycontact: "",
  Secondarycontact: "",
  Website: "",
  Location: "",
  Title: "",
  Price: "",
  Description: "",
  Farmlandpictures: "",
  Farmlanddetails: "",
  Neighbourhood: "",
};

const DeliveryServiceValues = {
  Name: "",
  mobileNumber: "",
  pincode: "",
  address: "",
  area: "",
  landmark: "",
  city: "",
  Location: "",
  emailAddress: "",
  orderDetails: "",
  uploadImage: undefined,
};

const DesignServiceValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  typeOfSite: [],
  otherType: "",
};

const PrivateJobValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  profession: "",
  cityPreference: "",
  coverLetter: "",
  preferedSal: "",
  jobLooking: [],
};

const OverseasEducationValues = {
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  coursePrefered: "",
  cityPreference: "",
};

export {
  baseURL,
  PropertyListValues,
  DoorStepDeliveryServiceValues,
  openPlotValues,
  flatApartmentValues,
  individualHousesValues,
  farmLandValues,
  rentalServicesValues,
  shiftingServicesValues,
  pestControlServiceValues,
  commercialRentalServicesValues,
  shiftingServiceValues,
  loanServiceValues,
  homeCleaningServiceValues,
  carpenterServiceValues,
  electricianServiceValues,
  paintingServiceValues,
  plumbingServiceValues,
  TvServiceValues,
  WashingMachineServiceValues,
  GeyserServiceValues,
  WaterPurifierServiceValues,
  MWServiceValues,
  ACServiceValues,
  MobileServiceValues,
  Locations,
  LEDRentalServiceValues,
  AutoCampaignValues,
  LeafletDistributionValues,
  BannerPastingValues,
  DigitalMarketingValues,
  AddFlatValues,
  AddPlotValues,
  AddFarmlandValues,
  MobilesForSaleValues,
  DeliveryServiceValues,
  DesignServiceValues,
  PrivateJobValues,
  OverseasEducationValues,
  OutdoorMarketingValues,
};
