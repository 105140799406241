import React from "react";
import DeliveryServiceFormModal from "../Modals/DeliveryServiceFormModal";
import { DoorStepDeliveryServiceValues } from "../Common/InitialValues";
import Section from "../Section";
import Card from "../../UI/Card";

export const DeliveryServices = () => {
  return (
    <Section className="home_services" id="serviceDoorstepDelivery">
      <div className="main_heading text-center">
        <h2>DoorStep Delivery</h2>
      </div>
      <div className="container">
        <Card>
          <div class="row">
            {DoorStepDeliveryServiceValues &&
              DoorStepDeliveryServiceValues.map((doorStepDeliverServ) => (
                <>
                  <div class="col-md-custom-5">
                    <a
                      class="single_service"
                      href="#"
                      data-toggle="modal"
                      data-target={`#${doorStepDeliverServ.serviceName.replace(
                        /[_\W]+/g,
                        "-"
                      )}Modal`}
                    >
                      <div class="service_icon">
                        <span class="service_ico">
                          <img
                            src={`images/${doorStepDeliverServ.imageURL}`}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div class="service_info">
                        <p>{doorStepDeliverServ.serviceName}</p>
                      </div>
                    </a>
                  </div>
                  <DeliveryServiceFormModal serviceInfo={doorStepDeliverServ} />
                </>
              ))}
          </div>
        </Card>
      </div>
    </Section>
  );
};

export default DeliveryServices;
