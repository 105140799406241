import React from "react";
import Input from "./Input";
import SelectControl from "./Select";
import CustomCheckbox from "./CustomCheckbox";
import CustomRadio from "./CustomRadio";
import Select from "./Select";
import Date from "./Date";
import InstructionsRadio from "./InstructionsRadio";
import CustomTextarea from "./CustomTextarea";

function FormikControl(props) {
  const { control, type, ...rest } = props;

  switch (type) {
        case "date":
          return <Date type={type} {...rest} />;
          break;
        case "instructradio":
          return <InstructionsRadio type={type} {...rest} />;
          break;
        case "checkbox":
          return <CustomCheckbox type={type} {...rest} />;
          break;
        case "radio":
          return <CustomRadio type={type} {...rest} />;
          break;
        case "select":
          return <Select type={type} {...rest} />;
          break;

          case "textarea":
          return <CustomTextarea {...rest} />;
          break;

    default:
      return <Input type={type} {...rest} />;
  }

}

export default FormikControl;
