import React, { useState } from "react";
import { Formik } from "formik";
import FormikControl from "../Common/Formik/FormikControl";
import { loanServiceValues } from "../Common/InitialValues";
import { loanServiceValidation } from "../Common/Validations";
import EnquiryMessage from "../../Utils/EnquiryMessage";

const LoanServicesModal = (props) => {
  const { clickedService } = props;

  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedServiceName, requestFrom) => {
    const {
      fullName,
      mobileNo,
      emailAddress,
      dateOfBirth,
      employmentType,
      companyName,
      monthlyIncome,
      residencePincode,
    } = values;
    const user_enquiry = `${selectedServiceName} - Date Of Birth : ${dateOfBirth} - Employment Type : ${employmentType} - Current Company Name : ${companyName} - Monthly Income : ${monthlyIncome} - Current Pincode : ${residencePincode}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <React.Fragment>
      <div
        className="modal centered_Modal selection_modal"
        id={`loanModal${clickedService.id}`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {clickedService.serviceName}
              <button
                type="button"
                className="close modal_close_btn"
                data-dismiss="modal"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="please_select">
                <Formik
                  initialValues={loanServiceValues}
                  validationSchema={loanServiceValidation}
                  onSubmit={(values, actions) => {
                    handleSubmitRegister(
                      values,
                      clickedService.serviceName,
                      requestType
                    );
                  }}
                >
                  {(formik) => {
                    const { handleChange, handleSubmit } = formik;
                    return (
                      <form
                        className="property_selection_form shifting_form"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                          <FormikControl
                            control="input"
                            type="date"
                            name="dateOfBirth"
                            label="Date Of Birth"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row">
                          <FormikControl
                            control="input"
                            type="select"
                            label="Employment Type"
                            name="employmentType"
                            onChange={handleChange}
                            options={[
                              { _id: 0, label: "Salaried" },
                              { _id: 1, label: "Self Employed" },
                            ]}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="companyName"
                            label="Current Company Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row">
                          <FormikControl
                            control="input"
                            type="text"
                            name="monthlyIncome"
                            label="Monthly Income"
                            onChange={handleChange}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="residencePincode"
                            label="Current Residence Pincode"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="gorm-group__btn form_send_options">
                          <button
                            type="submit"
                            className="btn btn-black btn-md send_by"
                            onClick={() => requestHandler("whatsApp")}
                          >
                            <img src="images/whatsapp.png" alt="img" /> Send By
                            Whatsapp
                          </button>
                          <button
                            type="submit"
                            className="btn btn-black btn-md send_by"
                            onClick={() => requestHandler("eMail")}
                          >
                            <img src="images/gmail.png" alt="img" /> Send By
                            Email
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
              <div className="basic_charge">
                <span>basic service charges starting from RS 599/-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoanServicesModal;
