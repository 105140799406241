import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const Input = (props) => {
  const { label, name, value, type, className, placeholder,multiple,onChange,accept } = props;

  const appendInput = () => {
    if(type == "file" && multiple){
      return (<Field
        type={type}
        name={name}
        className={`form-control ${className}`}
        placeholder={placeholder ? placeholder : label}
        value={value}
        multiple="multiple"
        onChange={onChange}
        accept={accept}
      />)
    }else{
      return (<Field
        type={type || "text"}
        name={name}
        className={`form-control ${className}`}
        placeholder={placeholder ? placeholder : label}
        value={value}
      />)
    }
  }

  return (
    <div className={`form-group form-field ${className}`}>
      <label className="form_label" htmlFor={name}>
        <span>{label}</span>
      </label>
      {appendInput()}
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Input;
