import React from "react";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import { Field } from "formik";

function Select(props) {
  const {
    name,
    optname,
    label,
    value,
    options,
    error,
    onChange,
    placeholder,
    ...rest
  } = props;

  return (
    <div className="form-group">
      <label className="form_label" htmlFor={label}>
        <span>{label}</span>
      </label>
      <Field
        as="select"
        name={name}
        optname={optname}
        className="form-control"
        placeholder="aasd"
        {...rest}
      >
        <option value="">Select</option>
        {options.map((option) => (
          <option key={option._id} value={option.label}>
            {optname ? option[label] : option.label}
          </option>
        ))}
      </Field>

      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Select;
