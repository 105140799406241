import React from "react";
import "./App.css";
import "./responsive.css";
import Header from "./Components/Header";
import Home from "./Components/Home/Home";
import Admin from "./Components/Admin/Admin";
import { Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer";
import CorporateServices from "./Components/Pages/CorporateServices";
import PropertyList from "./Components/Admin/Components/Property/PropertyList";
import PropertyDetails from "./Components/Admin/Components/Property/PropertyDetails";
import MobilesForSaleList from "./Components/Pages/MobilesForSaleList";


function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route path="/CorporateServices">
          <CorporateServices />
        </Route>
        <Route exact path="/add-property">
          <Admin />
        </Route>
        <Route exact path="/property-list">
          <PropertyList />
        </Route>
        <Route exact path="/property-details/:pid">
          <PropertyDetails />
        </Route>
        <Route exact path="/mobiles-list/:brand">
          <MobilesForSaleList />
        </Route>
        <Route path="*"> 
        <h2>Page Not Found</h2>
        </Route>
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
