const MainServicesArray = [
  {
    id: `MS${Math.floor(Math.random() * 100)}`,
    serviceName: "Assets search & validation services",
    serviceIcon: "real-estate.png",
  },
  {
    id: `MS${Math.floor(Math.random() * 100)}`,
    serviceName: "Rental Services",
    serviceIcon: "rentals.png",
  },
  {
    id: `MS${Math.floor(Math.random() * 100)}`,
    serviceName: "Design Services",
    serviceIcon: "website-designing.png",
  },
  {
    id: `MS${Math.floor(Math.random() * 100)}`,
    serviceName: "Loans & Credit Cards",
    serviceIcon: "bankingservices.png",
  },
  {
    id: `MS${Math.floor(Math.random() * 100)}`,
    serviceName: "Online/Offline Advertising Services",
    serviceIcon: "marketing.png",
  },
  {
    id: `DD${Math.floor(Math.random() * 100)}`,
    serviceName: "Doorstep Delivery",
    serviceIcon: "doorstep.png",
  },
  {
    id: `HS${Math.floor(Math.random() * 100)}`,
    serviceName: "Home service",
    serviceIcon: "housekeeping.png",
  },
  {
    id: `HS${Math.floor(Math.random() * 100)}`,
    serviceName: "Packers & Movers",
    serviceIcon: "house-shifting.png",
  },
  {
    id: `EE${Math.floor(Math.random() * 100)}`,
    serviceName: "Employement to Empowerment",
    serviceIcon: "education.png",
  },
];

const AssetValidationArray = [
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "open Plots",
    serviceIcon: "plot.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "Flats And Apartments",
    serviceIcon: "flats.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "Individual homes",
    serviceIcon: "home.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "Farm Lands",
    serviceIcon: "farmland.png",
  },
];

const RentalServicesArray = [
  {
    id: `RS${Math.floor(Math.random() * 100)}`,
    serviceName: "Apartments",
    serviceIcon: "real-estate.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "Offices",
    serviceIcon: "office-building.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "Individual Houses",
    serviceIcon: "home.png",
  },
  {
    id: `AV${Math.floor(Math.random() * 100)}`,
    serviceName: "commercial properties",
    serviceIcon: "shop.png",
  },
];

const PackersMoversArray = [
  {
    id: `PM${Math.floor(Math.random() * 100)}`,
    serviceName: "House Shifting",
    serviceIcon: "house-shifting.png",
  },
  {
    id: `PM${Math.floor(Math.random() * 100)}`,
    serviceName: "Office shifting",
    serviceIcon: "office-shifting.png",
  },
];

const LoanServicesArray = [
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Educational Loan",
    serviceIcon: "education-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Mortgage Loan",
    serviceIcon: "mortgage-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Personal Loan",
    serviceIcon: "bike-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Home Loan",
    serviceIcon: "home-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Car Loan",
    serviceIcon: "car-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Two-Wheeler Loan",
    serviceIcon: "bike-loan.png",
  },
  {
    id: `LS${Math.floor(Math.random() * 100)}`,
    serviceName: "Credit Card",
    serviceIcon: "credit-card.png",
  },
];

const HomeServicesArray = [
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Pest Control",
    serviceIcon: "pest-control.png",
  },
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Home Cleaning",
    serviceIcon: "housekeeping.png",
  },
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Carpenters",
    serviceIcon: "carpenter.png",
  },
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Electricians",
    serviceIcon: "electrician.png",
  },
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Home Painting",
    serviceIcon: "home-painting.png",
  },
  {
    id: Math.floor(Math.random() * 100),
    serviceName: "Plumbers",
    serviceIcon: "plumber.png",
  },
];

const mobileLaptopServiceArray = [
  {
    id: `ML${Math.floor(Math.random() * 100)}`,
    serviceName: "Mobile Repair",
    serviceIcon: "mobile-repair.png",
  },
  {
    id: `ML${Math.floor(Math.random() * 100)}`,
    serviceName: "Laptop Repair",
    serviceIcon: "laptop-repair.png",
  },
  {
    id: `ML${Math.floor(Math.random() * 100)}`,
    serviceName: "Desktop Repair",
    serviceIcon: "desktop-repair.png",
  },
];

const advertisingServiceArray = [
  {
    id: `AS${Math.floor(Math.random() * 100)}`,
    serviceName: "Auto Campaigns",
    serviceIcon: "campaign.png",
  },
  {
    id: `AS${Math.floor(Math.random() * 100)}`,
    serviceName: "Leaflets Distribution",
    serviceIcon: "distribution.png",
  },
  {
    id: `ML${Math.floor(Math.random() * 100)}`,
    serviceName: "Banners Pasting",
    serviceIcon: "banner-pasting.png",
  },
  {
    id: `ML${Math.floor(Math.random() * 100)}`,
    serviceName: "Digital Marketing (Google, Instagram, Facebook)",
    serviceIcon: "digital-marketing.png",
  },
];

const homePestControlTypeDetails = [
  {
    _id: "pc1",
    propertyType: "Apartment",
    duration: "60mins",
    propertyImg: "apartment.jpg",
    propertyServiceList: "Apartment - 1 BHK-Cockroaches & Ants Control",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
  {
    _id: "pc2",
    propertyType: "Bunglow",
    duration: "60mins",
    propertyImg: "apartment.jpg",
    propertyServiceList: "Bunglow - 2000-3000 sq.ft-Cockroaches & Ants Control",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to adult pests",
      },
    ],
  },
];

const shopPestControlTypeDetails = [
  {
    _id: "sc1",
    propertyType: "Retail Shops",
    duration: "45mins",
    propertyImg: "apartment.jpg",
    propertyServiceList:
      "Retail Shops - Less than 3000 sq. ft. -Cockroaches & Ants Controlment - 1 BHK-Cockroaches & Ants Control",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
      {
        heading: "Second Visit",
        desc:
          "Get treatment after two weeks to target nymphs and newly-hatched eggs",
      },
    ],
  },
];

const bathroomKitchenCleaningTypeDetails = [
  {
    _id: "bc1",
    propertyType: "Classic Bathroom Cleaning",
    duration: "30mins",
    propertyImg: "bathroom-cleaning.jpg",
    propertyServiceList:
      "Paint, rust & dirt strain removal. Rigorous cleaning done. Ideal for relocating",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
  {
    _id: "bc2",
    propertyType: "Move-in Kitchen Cleaning",
    duration: "180mins",
    propertyImg: "kitchen-cleaninf.jpg",
    propertyServiceList: "Empty Kitchen Cleaning",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to adult pests",
      },
    ],
  },
];

const balconyCarpenterServicDetails = [
  {
    _id: "cs1",
    propertyType: "Ceiling-mounted hanger installation",
    propertyImg: "ceiling-service.avif",
    propertyServiceList: "Installation of one clothes' hanger",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];
const bedCarpenterServicDetails = [
  {
    _id: "bs1",
    propertyType: "Bed support repair",
    propertyImg: "bed-repair.avif",
    propertyServiceList: "Repair of bed mattress support (any kind of bed)",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const doorCarpenterServicDetails = [
  {
    _id: "ds1",
    propertyType: "Accessory installation (any one)",
    propertyImg: "door-repair.jpg",
    propertyServiceList:
      "Installation or replacement of one latch/ chain/ stopper/ magnet",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];
const tvCarpenterServicDetails = [
  {
    _id: "ts1",
    propertyType: "TV installation (up to 48 inches)",
    propertyImg: "tv-repair.avif",
    propertyServiceList: "Installation of one TV set",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const switchServicDetails = [
  {
    _id: "ss1",
    propertyType: "Switch & socket",
    propertyImg: "ceiling-service.avif",
    propertyServiceList: "Installation of one AC switchbox",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const fanServicDetails = [
  {
    _id: "fs1",
    propertyType: "Ceiling fan regulator replacement",
    propertyImg: "ceiling-service.avif",
    propertyServiceList: "Replacement of one ceiling fan regulator",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const lightServicDetails = [
  {
    _id: "ls1",
    propertyType: "Bulbs installation (upto 5 bulbs)",
    propertyImg: "ceiling-service.avif",
    propertyServiceList: "Installation of up to five bulbs",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const homePaintingServiceDetails = [
  {
    _id: "hp1",
    propertyType: "Consultation & Painting Service",
    propertyImg: "painting-consultation.avif",
    propertyServiceList:
      "Consultation followed by quick & cost-effective painting service",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "At home consultation",
        desc:
          "Consultant understands your requirements for painting, waterproofing, textured, enamel painting & budget, evaluates the site & suggests affordable solution",
      },
      {
        heading: "Quotation & customer briefing",
        desc:
          "You review & accept the quote shared by the consultant & pay 35% prepayment. We allocate painters",
      },
      ,
      {
        heading: "Packaging & masking",
        desc: "Our painters cover furniture, doors & windows to prevent stains",
      },
      ,
      {
        heading: "Sanding & painting",
        desc:
          "Our painter sands the walls, apply waterproofing solution & paint depending on condition of walls",
      },
    ],
  },
];
const waterProofingServiceDetails = [
  {
    _id: "wp1",
    propertyType: "Water Proofing Consultation",
    propertyImg: "water-proof-painting.avif",
    propertyServiceList:
      "Protection against seepage that results into fungus, mould, and peeling paint",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const basinSinkServiceDetails = [
  {
    _id: "bss1",
    propertyType: "Waste pipe leakage",
    propertyImg: "ceiling-service.avif",
    propertyServiceList:
      "Repair of one broken or leaking single knob/ inlet tap",
    propertyServiceDescription:
      "Lorem ipsum dolor sit amet. Aut sint deserunt ad praesentium omnis et nihil excepturi? Id eligendi cumque ut deserunt beatae sit earum quam ut rerum repudiandae et porro.",
    serviceIncludes: [
      {
        heading: "Detailed Inspection",
        desc:
          "Professionals identity all kinds of pests in hidden and tricky areas",
      },
      {
        heading: "First Visit",
        desc: "Spray treatment to target adult pests",
      },
    ],
  },
];

const designServiceArray = [
  {
    id: `DS${Math.floor(Math.random() * 100)}`,
    serviceName: "website Design Services",
    serviceIcon: "website-designing.png",
  },
  {
    id: `DS${Math.floor(Math.random() * 100)}`,
    serviceName: "App Design Services",
    serviceIcon: "app-designing.png",
  },
];

export {
  MainServicesArray,
  AssetValidationArray,
  RentalServicesArray,
  PackersMoversArray,
  LoanServicesArray,
  HomeServicesArray,
  homePestControlTypeDetails,
  shopPestControlTypeDetails,
  bathroomKitchenCleaningTypeDetails,
  balconyCarpenterServicDetails,
  bedCarpenterServicDetails,
  doorCarpenterServicDetails,
  tvCarpenterServicDetails,
  switchServicDetails,
  fanServicDetails,
  lightServicDetails,
  homePaintingServiceDetails,
  waterProofingServiceDetails,
  basinSinkServiceDetails,
  mobileLaptopServiceArray,
  advertisingServiceArray,
  designServiceArray,
};
