import React from "react";
import Banner from "../Home/Banner";
import "./CorporateServices.css";
const CorporateServices = () => {
  return (
    <div className="home-page">
      <Banner page="CorporateServices" />
      <section className="corporate_services_section">
        <div className="container">
          <div className="main_heading text-center">
            <h2>Corporate Services</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="cservices_div">
                <ul className="cservices_list">
                  <li>LED screen services</li>
                  <li>AC Service </li>
                  <li>Electrician works </li>
                  <li>Floor Cleaning Deeply ( 1 yr warranty)</li>
                  <li>Wall painting ( white wash 2yrs Warrranty)</li>
                  <li>Carpenter works( yearly once )</li>
                  <li>Pest control ( 1 yr warranty )</li>
                  <li>GSB ( Main Board Cleaning )</li>
                  <li>Inventor Checking ( Battery water Check nd Filling )</li>
                  <li>
                    Offline Promotions ( Auto tops,Leaflets Distribution, No
                    parking sign Boards, Auto campaign)
                  </li>
                  <li>
                    For retail chains, corporate showrooms, automobile
                    showrooms, cellphone retail chain etc
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CorporateServices;
