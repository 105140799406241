import { Formik } from "formik";
import React, { useState } from "react";
import EnquiryMessage from "../../Utils/EnquiryMessage";
import FormikControl from "../Common/Formik/FormikControl";
import { shiftingServiceValues, Locations } from "../Common/InitialValues";
import { shiftingServiceValidation } from "../Common/Validations";

const PackersMoversModal = (props) => {
  const { clickedService } = props;

  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedServiceName, requestFrom) => {
    const { pincode, address, area, landmark, city, Location } = values;
    const { fullName, emailAddress, mobileNo } = values;
    const user_address = `H.No/Apartment Name : ${address} - landmark : ${landmark} - area : ${area} - city : ${city} - Location : ${Location} - Pincode : ${pincode}`;

    const user_enquiry = `${selectedServiceName} - Moving From : ${values.movingFrom} - Moving To : ${values.movingTo} - Shifting Date : ${values.shiftingDate} - Shifting Property Size : ${values.instructions}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_address) whatsAppText += "Address: " + user_address + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_address,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <div
      className="modal centered_Modal selection_modal"
      id={`packer${clickedService.id}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {clickedService.serviceName}
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={shiftingServiceValues}
                validationSchema={shiftingServiceValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(
                    values,
                    clickedService.serviceName,
                    requestType
                  );
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit, values } = formik;
                  return (
                    <form
                      className="property_selection_form shifting_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="text"
                          name="fullName"
                          label="Full Name"
                          onChange={handleChange}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          name="mobileNo"
                          label="Mobile Number"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="text"
                          name="pincode"
                          label="Pincode"
                          onChange={handleChange}
                          placeholder="6 digits [0-9] PIN code"
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          name="address"
                          label="Address"
                          onChange={handleChange}
                          placeholder="Flat, House no., Building, Company, Apartment"
                        />
                      </div>
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="text"
                          name="area"
                          label="Area, Street, Village"
                          onChange={handleChange}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          name="landmark"
                          label="Landmark"
                          onChange={handleChange}
                          placeholder="E.g. near apollo hospital"
                        />
                      </div>
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="text"
                          name="city"
                          label="Town/City"
                          onChange={handleChange}
                        />
                        <FormikControl
                          control="input"
                          type="select"
                          label="Your Location"
                          name="Location"
                          onChange={handleChange}
                          options={Locations}
                        />
                      </div>
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="text"
                          name="movingFrom"
                          label="Moving From"
                          onChange={handleChange}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          name="movingTo"
                          label="Moving To"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row">
                        <FormikControl
                          control="input"
                          type="date"
                          name="shiftingDate"
                          label="Shifting Date"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="moving_items" id="moving_items">
                        <div className="form-group">
                          <div className="form-group">
                            <div className="form-check">
                              <FormikControl
                                control="input"
                                type="instructradio"
                                label="Instructions"
                                name="instructions"
                                onChange={handleChange}
                                selectedValue={values.instructions}
                                options={[
                                  { _id: 0, label: "1RK" },
                                  { _id: 1, label: "1BHK" },
                                  { _id: 2, label: "2BHK" },
                                  { _id: 3, label: "3BHK" },
                                  { _id: 4, label: "4BHK" },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackersMoversModal;
