import React from "react";
import Card from "../../UI/Card";
import LEDRentalServiceModal from "../Modals/LEDRentalServiceModal";
import Section from "../Section";
import AutoCampaignServiceModal from "../Modals/AutoCampaignServiceModal";
import LeafletDistributionServiceModal from "../Modals/LeafletDistributionServiceModal";
import BannersPastingServiceModal from "../Modals/BannersPastingServiceModal";
import DigitalMarketingServiceModal from "../Modals/DigitalMarketingServiceModal";
import OutdoorAdvtModal from "../Modals/OutdoorAdvtModal";

const AdvertisingServices = () => {
  return (
    <React.Fragment>
      <Section
        className="home_services"
        id="serviceOnlineOfflineAdvertisingServices"
      >
        <div className="main_heading text-center">
          <h2>Online/Offline Advertising</h2>
        </div>
        <div className="container">
          <Card>
            <div className="row">
              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#LED_rental_Modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/led-screen.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p> LED screen rentals</p>
                  </div>
                </a>
              </div>

              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#auto_campaign_modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/campaign.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p>Auto Campaigns</p>
                  </div>
                </a>
              </div>
              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#leaflet_modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/campaign.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p>Leaflets Distribution</p>
                  </div>
                </a>
              </div>
              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#banner_pasting_modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/campaign.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p>Banners Pasting</p>
                  </div>
                </a>
              </div>
              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#marketing_modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/campaign.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p>Digital Marketing (Google, Instagram, Facebook)</p>
                  </div>
                </a>
              </div>
              <div className="col-md-custom-5">
                <a
                  className="single_service"
                  href="#"
                  data-toggle="modal"
                  data-target="#outdoor_modal"
                >
                  <div className="service_icon">
                    <span className="service_ico">
                      <img src="images/campaign.png" alt="img" />
                    </span>
                  </div>
                  <div className="service_info">
                    <p>Outdoor Advertisng</p>
                  </div>
                </a>
              </div>
            </div>
          </Card>
        </div>
      </Section>
      <LEDRentalServiceModal />
      <AutoCampaignServiceModal />
      <LeafletDistributionServiceModal />
      <BannersPastingServiceModal />
      <DigitalMarketingServiceModal />
      <OutdoorAdvtModal />
    </React.Fragment>
  );
};

export default AdvertisingServices;
