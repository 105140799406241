import React from "react";
import Card from "../../UI/Card";
import DesignModal from "../Modals/DesignModal";

const DesignServices = (props) => {
  function removeSpace(service) {
    return `#service${service.serviceName.replace(
      /[&\/\\#,+()$~%.' ":*?<>{}]/g,
      ""
    )}`;
  }

  return (
    <section className="services_container col-md-6" id="serviceDesignServices">
      <div className="main_heading text-center">
        <h2>Design Services</h2>
      </div>
      <div className="container">
        <Card>
          <div className="row">
            {props.ServicesList &&
              props.ServicesList.map((service, i) => (
                <>
                  <div className="col-md-6" key={i}>
                    <a
                      href={removeSpace(service)}
                      data-toggle="modal"
                      className="single_service"
                      data-target={`#design${service.id}`}
                    >
                      <div className="service_icon">
                        <span className="service_ico">
                          <img
                            src={`images/${service.serviceIcon}`}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="service_info">
                        <p>{service.serviceName}</p>
                      </div>
                    </a>
                    <DesignModal clickedService={service} />
                  </div>
                </>
              ))}
          </div>
        </Card>
      </div>
    </section>
  );
};

export default DesignServices;
