import { Formik } from "formik";
import React, { useState } from "react";
import FormikControl from "../Common/Formik/FormikControl";
import { openPlotValues } from "../Common/InitialValues";
import { openPlotValidation } from "../Common/Validations";
import Slider from "@mui/material/Slider";
import EnquiryMessage from "../../Utils/EnquiryMessage";

const OpenPlotsModal = () => {
  const [value, setValue] = useState([2, 100]);
  const [area, setArea] = useState([0, 4000]);

  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, requestFrom) => {
    values.priceMinValue = value[0];
    values.priceMaxValue = value[1];
    values.areaMinValue = area[0];
    values.areaMaxValue = area[1];

    const { fullName, emailAddress, mobileNo } = values;
    const user_enquiry = `${values.propertyType} - Price Range : ${values.priceMinValue}L - ${values.priceMaxValue}L -  Area Range : ${values.areaMinValue}SQ.ft - ${values.areaMaxValue}SQ.ft - Other Services - ${values.otherServices}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };
  function valuetext(value) {
    return `${value}L`;
  }
  const handlePriceChange = (e, newValue) => {
    setValue(newValue);
  };

  function areavaluetext(value) {
    return `${area}L`;
  }

  const handleChangeArea = (event, newValue) => {
    setArea(newValue);
  };

  return (
    <div className="modal centered_Modal selection_modal" id="open_plots_modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Open Plots
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={openPlotValues}
                validationSchema={openPlotValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values, requestType);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form_label">
                          Price Range (in Lakhs)
                        </label>
                        <div className="price_slide">
                          <FormikControl
                            control="input"
                            type="text"
                            name="priceMinValue"
                            className="price_min"
                            disabled="true"
                            value={value[0]}
                          />
                          <Slider
                            getAriaLabel={() => "Price Range"}
                            value={value}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            min={2}
                            max={100}
                            name="priceRangeSlider"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="priceMaxValue"
                            className="price_max"
                            disabled="true"
                            value={value[1]}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form_label">Other Services</label>
                        <FormikControl
                          control="input"
                          type="checkbox"
                          name="otherServices"
                          onChange={handleChange}
                          options={[
                            { _id: "op0", label: "Permissions Verification" },
                            { _id: "op1", label: "Registration Paper Work" },
                            {
                              _id: "op2",
                              label: "Property Title Verification",
                            },
                          ]}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form_label">Area (in SQ.Yds)</label>
                        <div className="price_slide">
                          <FormikControl
                            control="input"
                            type="text"
                            name="areaMinValue"
                            className="price_min"
                            disabled="true"
                            value={area[0]}
                          />
                          <Slider
                            getAriaLabel={() => "Area"}
                            value={area}
                            onChange={handleChangeArea}
                            valueLabelDisplay="auto"
                            getAriaValueText={areavaluetext}
                            name="farmAreaRangeSlider"
                            min={0}
                            max={4000}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="areaMaxValue"
                            className="price_max"
                            disabled="true"
                            value={area[1]}
                          />
                        </div>
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPlotsModal;
