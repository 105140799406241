import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../Admin/Components/Breadcrumbs';
import { MobilesForSaleValues } from '../Common/InitialValues';
import DeliveryServiceFormModal from '../Modals/DeliveryServiceFormModal';

const MobilesForSaleList = () => {
    const [crumbs,setCrumbs] = useState(['home','Mobiles list']);

    const {brand} = useParams();

    const filteredProducts = MobilesForSaleValues.filter(product => product.brand === brand);
    

    





  return (
    <div className='property_list_div'>
        <Breadcrumbs crumbs={crumbs} />
    <div className='mobileslist'>
    <div className='container'>
        <h3>Mobiles List</h3>
        <div className='property_row'>
            <ul className='property_list'>
             {filteredProducts && filteredProducts.map(product => (
                <>
                <li class="property_list_li">
                    <div class="">
                        <div class="single_property">
                            <div class="property_images prodct_images">
                                <a href="#">
                                    <img src={`${product.image}`} />
                                    </a>
                                    </div>
                                    <div class="property_info">
                                        <div class="info_inner">
                                            <h6><a href="#">{product.name}</a></h6>
                                            <div class="property_cost"><h4>₹{Math.round(product.price * ((100-product.discountPercentage)/100))}</h4></div>
                                            <div className='direct_cost'><span>₹{product.price}</span>{product.discountPercentage}% off</div>
                                            <div class="property_btns"><a class="btn btn-yellow" href="/property-details/p1">More Details</a>
                </div>
                </div>
                </div>
                </div>
                </div>
                </li>
                <DeliveryServiceFormModal />
                </>
             ))}
            </ul>
        </div>
        </div>
    </div>
    </div>
  )
}

export default MobilesForSaleList