import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {baseURL, PropertyListValues} from "../../../Common/InitialValues";
import Breadcrumbs from '../Breadcrumbs';
import "./PropertyDetails.css";

const PropertyDetails = () => {
    const {pid} = useParams();

    const filteredProperty = PropertyListValues.find(property => property.id === pid);
  
    const [crumbs,setCrumbs] = useState(['home','property details',`${filteredProperty.Title}`]);



  return (
    <div className='property_list_div single_prop_details'>
        <Breadcrumbs crumbs={crumbs} />
        {filteredProperty && <div className='container'>
           
           <div className='card'>
           <div className='row'>
           <div className='col-md-6 p-0'>
            {filteredProperty.Images.length < 4 ?
            <div className='property_all_images'>
            <div className='property_single_img'>
              <div className='property_img_card p-0 hover-zoom-in'>
                  <a href='javascript:void(0)' data-toggle="modal" data-target={`#modal_${filteredProperty.id}`}>
              <img src={`${baseURL}${filteredProperty.Images[0]}`}/>
              {filteredProperty.Images.length < 2 ? "": <span className='images_count'>{filteredProperty.Images.length - 1}+</span>}
              </a>
              </div>
            </div>
         </div>
             : 
             <div className='property_all_images'>
                 <a href='javascript:void(0)' data-toggle="modal" data-target={`#modal_${filteredProperty.id}`}>
                 <div className='property_single_img'>
                   <div className='property_img_card p-0 hover-zoom-in'>
                      
                   <img src={`${baseURL}${filteredProperty.Images[0]}`} />
                   
                   </div>
                 </div>
                 <div className='property_single_img'>
                 <img src={`${baseURL}${filteredProperty.Images[1]}`} />
                   <div className='image_with_more_link'>
                   <img src={`${baseURL}${filteredProperty.Images[2]}`} />
                   {filteredProperty.Images.length < 4 ? "": <span className='images_count'>{filteredProperty.Images.length - 3}+</span>}
                   </div>
                 </div>
                 </a>
              </div>
             }
              
           </div>
           <div className='col-md-6 pl-5'>
               <div className=''>
               <div className='prop_full_details'>
               <div className='prop_title'>
                   <h3>{filteredProperty.Title}</h3>
               </div>
               <div className='prop_address text-muted'>
               <i class="fa-sharp fa-solid fa-location-dot"></i>  {filteredProperty.Address}
               </div>
               <div className='prop_desc'>
               <p>{filteredProperty.Description}</p></div>
               <div class="property_cost mt-4"><h4><span class="rupees">₹</span>{filteredProperty.Price}</h4></div>
               <div class="property_btns mt-4"><button type="submit" class="btn btn-yellow">Contact Us</button></div>
               </div>
               </div>
               </div>
               </div>
           </div>
           <div className='row'>
               <div className='col-md-6 pl-0'>
               <div className='card property_features p-4 mt-3'>
            <h3 className='features_heading'><span>Flat Details</span></h3>
            <div className='features_div'>
               
                <ul className='features_list'>
                  <li>
                  <div className='single_feature'>
                       <div className='feature_label'>Built-up Area</div>
                       <div className='feature_content'>{filteredProperty.PropertyDetails.Area}</div>
                  </div>
              </li>
           <li>
               <div className='single_feature'>
                    <div className='feature_label'>Floor</div>
                    <div className='feature_content'>{filteredProperty.PropertyDetails.Floor}</div>
               </div>
           </li>
           <li>
               <div className='single_feature'>
                    <div className='feature_label'>Parking</div>
                    <div className='feature_content'>
                      {
                        filteredProperty.PropertyDetails.Parking.map(function(item, index) {
                          return <span key={`ps${index}`}>{ (index ? ', ' : '') + item }</span>
                        })
                      }
                    </div>
               </div>
           </li>
           <li>
               <div className='single_feature'>
                    <div className='feature_label'>Gated Security  </div>
                    <div className='feature_content'>{filteredProperty.PropertyDetails.GatedSecurity}   </div>
               </div>
           </li>
           </ul>
                  
               
            </div>
           </div>
               </div>
               <div className='col-md-6 pl-0'>
               <div className='card property_features p-4 mt-3'>
            <h3 className='features_heading'><span>Neighbourhood   </span></h3>
            <div className='features_div'>
               <ul className='features_list'>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_label'>Educational Institutes</div>
                            <div className='feature_content'>
                        {filteredProperty.Neighbourhood && filteredProperty.Neighbourhood.Institutes.map(function(item, index) {
                          return <span key={`ps${index}`}>{ (index ? ', ' : '') + item }</span>
                        })
                      } </div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_label'>Shopping Centers</div>
                            <div className='feature_content'>
                        {filteredProperty.Neighbourhood && filteredProperty.Neighbourhood.ShoppingMalls.map(function(item, index) {
                          return <span key={`sm${index}`}>{ (index ? ', ' : '') + item }</span>
                        })
                      }
                            </div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_label'>Hospital</div>
                            <div className='feature_content'>
                        {filteredProperty.Neighbourhood && filteredProperty.Neighbourhood.Hospitals.map(function(item, index) {
                          return <span key={`ho${index}`}>{ (index ? ', ' : '') + item }</span>
                        })
                      }
                            </div>
                       </div>
                   </li>
               </ul>
            </div>
           </div>
               </div>
           </div>

           <div className='row'>
               <div className='col-md-6 pl-0'>
               <div className='card property_features p-4 mt-3'>
            <h3 className='features_heading'><span>Amenities</span></h3>
            <div className='features_div'>
               <ul className='features_list amenities_list'>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_content'>Lift</div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_content'>Security</div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_content'>Visitor parking</div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_content'>Water Storage</div>
                       </div>
                   </li>
                   <li>
                       <div className='single_feature'>
                            <div className='feature_content'>Water Storage</div>
                       </div>
                   </li>
               </ul>
            </div>
           </div>
               </div>
               <div className='col-md-6 pl-0'>
               <div className='card property_features p-4 mt-3'>
            <h3 className='features_heading'><span>Project Highlights   </span></h3>
            <div className='highlight_points'>
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
            </div>
           </div>
               </div>
           </div>

           
       </div>}
        
    </div>
  )
}

export default PropertyDetails