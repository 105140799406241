import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {PropertyListValues} from "../../../Common/InitialValues";
import Breadcrumbs from '../Breadcrumbs';
import "./PropertyList.css";

const PropertyList = () => {

   const [crumbs,setCrumbs] = useState(['home','property list']);


  return (
    <div className='property_list_div'>
      <Breadcrumbs crumbs={crumbs} />
      <div className='container'>
         <h3>31019 results | Flats for Sale in Hyderabad</h3>
         <div className='property_row'>
      <ul className='property_list'>
         
        {PropertyListValues && PropertyListValues.map((property,index) => {
          return <li className='property_list_li' key={index}>
           <div className='card'>
           <div className='single_property'>
              <div className='property_images'>
                 <a href='#'>
                    <img src={property.Images[0]} />
                    {property.Images.length < 2 ? "": <span className='images_count'>{property.Images.length-1}+</span>}
                    
                 </a>
              </div>
              <div className='property_info'>
               <div className='info_inner'>
               <h6><a href='#'>
               {property.Title}
                 </a></h6>
                <div className='property_address'>
                {property.Title}
                </div>
                 <div className='property_description'>
                 <p>{property.Description} </p>
                    <a className='desc_More' href='#'>Read More</a>
                    </div>
                    <div className='property_cost'>
               <h4><span className="rupees">₹</span>{property.Price}</h4>
              </div>
              <div className='property_btns'>
              <button type="submit" className="btn btn-black">Contact</button>
              <Link className="btn btn-yellow" to={`property-details/${property.id}`}>
                  More Details
                </Link>
              </div>
                    </div>
                    
              </div>
              </div>
              
           </div>
         </li>
        })}
        
       
        
      </ul>
      </div>
      </div>
    </div>
  )
}

export default PropertyList