import { Formik } from "formik";
import React, { useState } from "react";
import EnquiryMessage from "../../Utils/EnquiryMessage";
import FormikControl from "../Common/Formik/FormikControl";
import { Locations, MobileServiceValues } from "../Common/InitialValues";
import { MobileServicesValidation } from "../Common/Validations";

const MobileServiceModal = (props) => {
  const { Service, clickedService } = props;
  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, applianceType, requestFrom) => {
    const { pincode, address, area, landmark, city, Location } = values;
    const { fullName, emailAddress, mobileNo } = values;
    const user_address = `H.No/Apartment Name : ${address} - landmark : ${landmark} - area : ${area} - city : ${city} - Location : ${Location} - Pincode : ${pincode}`;
    const user_enquiry = `${applianceType}: Brand name - ${values.brandName} : Model - ${values.model} :  Service - ${values.Service}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_address) whatsAppText += "Address: " + user_address + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on: " + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_address,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  const handleServiceArray = () => {
    if (clickedService && clickedService.serviceName === "Mobile Repair") {
      return [
        {
          _id: "mrs0",
          label: "Mobile Overheating",
        },
        {
          _id: "mrs1",
          label: "Water Damage",
        },
        {
          _id: "mrs2",
          label: "Signal Issues",
        },
        {
          _id: "mrs3",
          label: "Cracked Screen",
        },
        {
          _id: "mrs4",
          label: "Motherboard Problem",
        },
        {
          _id: "mrs5",
          label: "Battery Issues",
        },
      ];
    } else if (
      clickedService &&
      clickedService.serviceName === "Laptop Repair"
    ) {
      return [
        {
          _id: "lrs0",
          label: "Overheating",
        },
        {
          _id: "lrs1",
          label: "Water Damage",
        },
        {
          _id: "lrs2",
          label: "Cracked Screen",
        },
        {
          _id: "lrs3",
          label: "Motherboard Problem",
        },
        {
          _id: "lrs4",
          label: "Battery Issues",
        },
      ];
    } else if (
      clickedService &&
      clickedService.serviceName === "Desktop Repair"
    ) {
      return [
        {
          _id: "drs1",
          label: "Water Damage",
        },
        {
          _id: "drs2",
          label: "Cracked Screen",
        },
        {
          _id: "drs3",
          label: "Motherboard Problem",
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div
      className="modal centered_Modal selection_modal"
      id={`mlModal${Service && Service.id}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {Service && Service.serviceName}
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={MobileServiceValues}
                validationSchema={MobileServicesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(
                    values,
                    clickedService.serviceName,
                    requestType
                  );
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="pincode"
                            label="Pincode"
                            onChange={handleChange}
                            placeholder="6 digits [0-9] PIN code"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="address"
                            label="Address"
                            onChange={handleChange}
                            placeholder="Flat, House no., Building, Company, Apartment"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="area"
                            label="Area, Street, Village"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="landmark"
                            label="Landmark"
                            onChange={handleChange}
                            placeholder="E.g. near apollo hospital"
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="city"
                            label="Town/City"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="select"
                            label="Your Location"
                            name="Location"
                            onChange={handleChange}
                            options={Locations}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="brandName"
                            label="Brand Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="model"
                            label="Enter Model"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="radio"
                        label="Services"
                        name="Service"
                        className="pest_service"
                        onChange={handleChange}
                        options={handleServiceArray()}
                      />

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileServiceModal;
