import React from 'react';
import { Link } from 'react-router-dom';

  

const Breadcrumbs = (props) => {

  function isLast(index){
    return index === props.crumbs.length - 1;
 } 


  return (
    <nav className='row'>
      <div className='container'>
      <ol className='breadcrumb custom_breadcrumb'>
        {
          props.crumbs.map((crumb,ci) => {
            const disabled = isLast(ci) ? 'disabled' : "";
            const crumbLink = crumb.replace(/[^A-Z0-9]/ig, "-");
            return (
              
              <li
              key={ci}
              className="breadcrumb-item align-items-center"
              >
                <Link className={`breadcrumb-link ${disabled}`} to={`/${crumbLink}`}>{crumb}</Link>
              </li>
            )
          })
        }
        </ol>
        </div>
    </nav>
  )
}

export default Breadcrumbs