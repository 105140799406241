import emailjs from "@emailjs/browser";

function EnquiryMessage(enquiryInfo, enquiryWith) {
  if (enquiryWith === "whatsApp") {
    const whatsAppBusinessNum = +918712355455;
    window.open(
      `http://api.whatsapp.com/send?phone=${whatsAppBusinessNum}&text=${enquiryInfo}`,
      "_blank"
    );
  } else if (enquiryWith === "eMail") {
    emailjs
      .send(
        "service_efpc53m",
        "template_jwkgcnt",
        enquiryInfo,
        "tbMG0k9q0Jrudt-BN"
      )
      .then(
        (result) => {
          alert("email Sent Successfully.");
        },
        (error) => {
          alert(
            "Sorry for the inconvience, Please reach us at eesytech25@gmail.com"
          );
        }
      );
  } else {
    console.log(
      "Sorry for the inconvience, Please reach us at eesytech25@gmail.com"
    );
  }
}

export default EnquiryMessage;
