import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import FormikControl from "../Common/Formik/FormikControl";
import { commercialRentalServicesValues } from "../Common/InitialValues";
import { commercialRentalServicesValidation } from "../Common/Validations";
import Slider from "@mui/material/Slider";
import EnquiryMessage from "../../Utils/EnquiryMessage";

const CommercialRentalModal = () => {
  const [area, setArea] = useState([10000, 400000]);

  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, rentalType, requestFrom) => {
    values.areaMinValue = area[0];
    values.areaMaxValue = area[1];

    const { fullName, emailAddress, mobileNo } = values;
    const user_enquiry = `${rentalType} Rentals - Rental Purpose : ${values.rentalPurpose} - rent_range : ${values.rentRangeCheck} -  area : ${values.areaMinValue}SQ.ft - ${values.areaMaxValue}SQ.ft - property_status - ${values.propertyStatus}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  function areavaluetext(value) {
    return `${area}`;
  }

  const handleChangeArea = (e, newValue) => {
    setArea(newValue);
  };

  return (
    <div
      className="modal centered_Modal selection_modal"
      id="commercial_rental_Modal"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Commercial Properties
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={commercialRentalServicesValues}
                validationSchema={commercialRentalServicesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(
                    values,
                    "Commercial Properties",
                    requestType
                  );
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="rentalPurpose"
                            label="Rental Purpose"
                            onChange={handleChange}
                            placeholder="EX:Mobile Showroom, Bookshop..."
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Rent Range"
                        name="rentRangeCheck"
                        onChange={handleChange}
                        options={[
                          { _id: 0, label: "₹5000 - ₹10000" },
                          { _id: 1, label: "₹10000 - ₹20000" },
                          { _id: 2, label: "₹20000 - ₹50000" },
                          { _id: 3, label: "₹50000 - ₹75000" },
                          { _id: 4, label: "₹75000 - ₹100000" },
                        ]}
                      />

                      <div className="form-group built_up">
                        <label className="form_label">
                          Built Up Area(sq. ft.)
                        </label>
                        <div className="price_slide">
                          <FormikControl
                            control="input"
                            type="text"
                            name="areaMinValue"
                            className="price_min"
                            disabled="true"
                            value={area[0]}
                          />
                          <Slider
                            getAriaLabel={() => "Area"}
                            value={area}
                            onChange={handleChangeArea}
                            valueLabelDisplay="auto"
                            getAriaValueText={areavaluetext}
                            name="farmAreaRangeSlider"
                            min={10000}
                            max={400000}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="areaMaxValue"
                            className="price_max"
                            disabled="true"
                            value={area[1]}
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="radio"
                        label="Property Status"
                        name="propertyStatus"
                        className="property_status"
                        onChange={handleChange}
                        options={[
                          { _id: 0, label: "Under Construction" },
                          { _id: 1, label: "Ready" },
                        ]}
                      />

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialRentalModal;
