import React, { useState } from "react";
import { Formik } from "formik";
import FormikControl from "../Common/Formik/FormikControl";
import { rentalServicesValues } from "../Common/InitialValues";
import { rentalServicesValidation } from "../Common/Validations";
import EnquiryMessage from "../../Utils/EnquiryMessage";

const ApartmentRentalModal = () => {
  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, rentalType, requestFrom) => {
    const { fullName, emailAddress, mobileNo } = values;
    const user_enquiry = `${rentalType} Rentals - property_size : ${values.propertySize} - property_status : ${values.propertyStatus} - price_range : ${values.priceRangeCheck} - other_services : ${values.otherServices}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };
  return (
    <div
      className="modal centered_Modal selection_modal"
      id="apartments_rental_Modal"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Apartments
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={rentalServicesValues}
                validationSchema={rentalServicesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values, "Apartments", requestType);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Select Below"
                        name="propertySize"
                        onChange={handleChange}
                        options={[
                          { _id: "arps0", label: "1RK" },
                          { _id: "arps1", label: "1BHK" },
                          { _id: "arps2", label: "2BHK" },
                          { _id: "arps3", label: "3BHK" },
                          { _id: "arps4", label: "4BHK" },
                          { _id: "arps5", label: "5BHK" },
                        ]}
                      />

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Price Range"
                        name="priceRangeCheck"
                        onChange={handleChange}
                        options={[
                          { _id: 0, label: "₹5000 - ₹10000" },
                          { _id: 1, label: "₹10000 - ₹20000" },
                          { _id: 2, label: "₹20000 - ₹50000" },
                          { _id: 3, label: "₹50000 - ₹75000" },
                          { _id: 4, label: "₹75000 - ₹100000" },
                        ]}
                      />

                      <FormikControl
                        control="input"
                        type="radio"
                        label="Property Status"
                        name="propertyStatus"
                        className="property_status"
                        onChange={handleChange}
                        options={[
                          { _id: "arpsr0", label: "Under Construction" },
                          { _id: "arpsr1", label: "Ready" },
                        ]}
                      />

                      <div className="form-group">
                        <FormikControl
                          control="input"
                          type="checkbox"
                          name="otherServices"
                          label="other Services"
                          onChange={handleChange}
                          options={[
                            { _id: "aros0", label: "Permissions Verification" },
                            { _id: "aros1", label: "Registration Paper Work" },
                            {
                              _id: "aros2",
                              label: "Property Title Verification",
                            },
                          ]}
                        />
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentRentalModal;
