import { Formik } from "formik";
import React, { useState } from "react";
import EnquiryMessage from "../../Utils/EnquiryMessage";
import FormikControl from "../Common/Formik/FormikControl";
import { PrivateJobValues } from "../Common/InitialValues";
import { PrivateJobsValiation } from "../Common/Validations";

const PrivateJobModal = () => {
  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedService, requestFrom) => {
    const {
      fullName,
      emailAddress,
      mobileNo,
      profession,
      cityPreference,
      coverLetter,
      preferedSal,
      jobLooking,
    } = values;
    const user_enquiry = `${selectedService} - profession : ${profession} - City Preference : ${cityPreference} - Cover Letter : ${coverLetter} - Prefered Salary : ${preferedSal} - Job Looking For : ${jobLooking}`;

    let whatsAppText = "";
    if (fullName) whatsAppText += "Hi Iam " + fullName + ", \n";
    if (mobileNo) whatsAppText += "Contact No: " + mobileNo + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_enquiry) whatsAppText += "I need an enquiry on:" + user_enquiry;

    let templateParams = {
      fullName,
      mobileNo,
      emailAddress,
      user_enquiry,
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };

  return (
    <div className="modal centered_Modal selection_modal" id="privateJobsModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Private Jobs
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={PrivateJobValues}
                validationSchema={PrivateJobsValiation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values, "Private Jobs", requestType);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="profession"
                            label="Profession"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="cityPreference"
                            label="City Preference"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="preferedSal"
                            label="Prefered Salary"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Job Looking For"
                        name="jobLooking"
                        className="pest_service"
                        onChange={handleChange}
                        options={[
                          { _id: "aspc0", label: "Corporate" },
                          { _id: "aspc1", label: "MNC" },
                          { _id: "aspc2", label: "Non Corporate" },
                          { _id: "aspc3", label: "Startup" },
                        ]}
                      />
                      <FormikControl
                        type="textarea"
                        name="coverLetter"
                        label="Cover letter"
                        className="textarea-main"
                        onChange={handleChange}
                      />
                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("whatsApp")}
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                          onClick={() => requestHandler("eMail")}
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="basic_charge">
              <span>basic service charges starting from RS 599/-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateJobModal;
