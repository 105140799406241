import React from 'react';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AddFarmland from './AddProperty/AddFarmland';
import AddFlat from './AddProperty/AddFlat';
import AddPlot from './AddProperty/AddPlot';

const PropertyTypeTabs = () => {
    const [key, setKey] = useState('flat');
  return (
    <Card className='col-md-8 mx-auto tab_card'>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="flat" title="Flat">
        <AddFlat />
      </Tab>
      <Tab eventKey="plot" title="Plots">
      <AddPlot />
      </Tab>
      <Tab eventKey="farmland" title="Farm Land">
      <AddFarmland />
      </Tab>
    </Tabs>
    </Card>
  )
}

export default PropertyTypeTabs