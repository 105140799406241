import React from "react";
import { Formik } from "formik";
import FormikControl from "../Common/Formik/FormikControl";
import { individualHousesValues } from "../Common/InitialValues";
import { individualHousesValidation } from "../Common/Validations";

const IndividualHousesModal = () => {
  const handleSubmitRegister = (values) => {
    console.log({
      user_name: values.fullName,
      user_email: values.emailAddress,
      user_phone: values.mobileNo,
      user_message: `${values.fullName.toLowerCase()} need a enquiry`,

      user_enquiry: `${values.propertyType}:property_size - ${values.propertySize} - property_status - ${values.propertyStatus}: price_range - ${values.priceRangeCheck}:other_services - ${values.otherServices}`,
    });
  };
  return (
    <div
      className="modal centered_Modal selection_modal"
      id="individual_houses_modal"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            Individual Houses
            <button
              type="button"
              className="close modal_close_btn"
              data-dismiss="modal"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="please_select">
              <Formik
                initialValues={individualHousesValues}
                validationSchema={individualHousesValidation}
                onSubmit={(values, actions) => {
                  handleSubmitRegister(values);
                }}
              >
                {(formik) => {
                  const { handleChange, handleSubmit } = formik;
                  return (
                    <form
                      className="property_selection_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="fullName"
                            label="Full Name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobileNo"
                            label="Mobile Number"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4 pl-0 pr-1">
                          <FormikControl
                            control="input"
                            type="text"
                            name="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Select Below"
                        name="propertySize"
                        onChange={handleChange}
                        options={[
                          { _id: "ih0", label: "1RK" },
                          { _id: "ih1", label: "1BHK" },
                          { _id: "ih2", label: "2BHK" },
                          { _id: "ih3", label: "3BHK" },
                          { _id: "ih4", label: "4BHK" },
                          { _id: "ih5", label: "5BHK" },
                        ]}
                      />

                      <FormikControl
                        control="input"
                        type="radio"
                        label="Property Status"
                        name="propertyStatus"
                        className="property_status"
                        onChange={handleChange}
                        options={[
                          { _id: "ihr0", label: "Under Construction" },
                          { _id: "ihr1", label: "Ready" },
                        ]}
                      />

                      <FormikControl
                        control="input"
                        type="checkbox"
                        label="Price Range"
                        name="priceRangeCheck"
                        onChange={handleChange}
                        options={[
                          { _id: "ihpr0", label: "10L - 20L" },
                          { _id: "ihpr1", label: "20L - 40L" },
                          { _id: "ihpr2", label: "40L - 60L" },
                          { _id: "ihpr3", label: "60L - 80L" },
                          { _id: "ihpr4", label: "80L - 1CR" },
                        ]}
                      />

                      <div className="form-group">
                        <FormikControl
                          control="input"
                          type="checkbox"
                          name="otherServices"
                          label="other Services"
                          onChange={handleChange}
                          options={[
                            { _id: "ihos0", label: "Permissions Verification" },
                            { _id: "ihos1", label: "Registration Paper Work" },
                            {
                              _id: "ihos2",
                              label: "Property Title Verification",
                            },
                          ]}
                        />
                      </div>

                      <div className="gorm-group__btn form_send_options">
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                        >
                          <img src="images/whatsapp.png" alt="img" /> Send By
                          Whatsapp
                        </button>
                        <button
                          type="submit"
                          className="btn btn-black btn-md send_by"
                        >
                          <img src="images/gmail.png" alt="img" /> Send By Email
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualHousesModal;
