import { Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import ReactDOM from 'react-dom';
import EnquiryMessage from '../../Utils/EnquiryMessage';
import FormikControl from '../Common/Formik/FormikControl';
import { DeliveryServiceValues, Locations } from '../Common/InitialValues';
import { DeliveryServiceValiation } from '../Common/Validations';

const DeliveryServiceFormModal = ({serviceInfo}) => {
  const { serviceName } = serviceInfo;

  console.log(serviceName);


  const [requestType, setRequestType] = useState();

  const requestHandler = (reqType) => {
    setRequestType(reqType);
  };

  const handleSubmitRegister = (values, selectedServiceName, requestFrom) => {
    const {
      Name,
      emailAddress,
      mobileNumber,
      address,
      landmark,
      area,
      city,
      Location,
      pincode,
      orderDetails,
      uploadImage
    } = values;
    const user_address = `H.No/Apartment Name : ${address} - landmark : ${landmark} - area : ${area} - city : ${city} - Location : ${Location} - Pincode : ${pincode}`;
    const user_enquiry = `${orderDetails}`;

    let whatsAppText = "";
    if (Name) whatsAppText += "Hi Iam " + Name + ", \n";
    if (mobileNumber) whatsAppText += "Contact No: " + mobileNumber + ", \n";
    if (emailAddress) whatsAppText += "Email: " + emailAddress + ", \n";
    if (user_address) whatsAppText += "Address: " + user_address + ", \n";
    if (orderDetails) whatsAppText += "I need an Delivery Of:" + user_enquiry + ", \n";
    if (uploadImage) whatsAppText += "Images:" + uploadImage;


    let templateParams = {
      Name,
      mobileNumber,
      emailAddress,
      user_address,
      user_enquiry,
      uploadImage
    };

    if (requestFrom === "whatsApp") {
      EnquiryMessage(whatsAppText, "whatsApp");
    } else if (requestFrom === "eMail") {
      EnquiryMessage(templateParams, "eMail");
    }
  };


  return ReactDOM.createPortal(
    <React.Fragment>
      {<div
        className="modal centered_Modal selection_modal"
        id={`${serviceName.replace(/[_\W]+/g, "-")}Modal`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {serviceName}
              <button
                type="button"
                className="close modal_close_btn"
                data-dismiss="modal"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="please_select">
                <Formik
                  initialValues={DeliveryServiceValues}
                  validationSchema={DeliveryServiceValiation}
                  onSubmit={(values, actions) => {
                    handleSubmitRegister(
                      values,
                      serviceName,
                      requestType
                    );
                  }}
                >
                  {(formik) => {
                    const { handleChange, handleSubmit } = formik;
                    return (
                      <form
                        className="property_selection_form shifting_form"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <FormikControl
                            control="input"
                            type="text"
                            name="Name"
                            label="Full Name"
                            className="form-main"
                            onChange={handleChange}
                          />
                          <FormikControl
                            control="input"
                            type="tel"
                            name="mobileNumber"
                            label="Mobile Number"
                            className="form-main"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row">
                          
                          <FormikControl
                            control="input"
                            type="email"
                            name="emailAddress"
                            label="Email Address"
                            className="form-main"
                            onChange={handleChange}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="pincode"
                            label="Pincode"
                            onChange={handleChange}
                            placeholder="6 digits [0-9] PIN code"
                          />
                        </div>

                        <div className="row">
                          
                        <FormikControl
                            control="input"
                            type="text"
                            name="address"
                            label="Address"
                            onChange={handleChange}
                            placeholder="Flat, House no., Building, Company, Apartment"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="area"
                            label="Area, Street, Village"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="row">
                        <FormikControl
                            control="input"
                            type="text"
                            name="landmark"
                            label="Landmark"
                            onChange={handleChange}
                            placeholder="E.g. near apollo hospital"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="city"
                            label="Town/City"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="row">
                        <FormikControl
                            control="input"
                            type="select"
                            label="Your Location"
                            name="Location"
                            onChange={handleChange}
                            options={Locations}
                          />
                        </div>
                        <div className='row'>
                          <FormikControl
                            type="textarea"
                            name="orderDetails"
                            label="Write Your Order Details"
                            className="textarea-main"
                            onChange={handleChange}
                          />
                        </div>
                        <div className='row'>
                          <FormikControl
                            type="file"
                            name="uploadImage"
                            label="Upload Images"
                            className="fileupload-main"
                            onChange={handleChange}
                          />
                        </div>


                        <div className="gorm-group__btn form_send_options">
                          <button
                            type="submit"
                            className="btn btn-black btn-md send_by"
                            onClick={() => requestHandler("whatsApp")}
                          >
                            <img src="images/whatsapp.png" alt="img" /> Send By
                            Whatsapp
                          </button>
                          <button
                            type="submit"
                            className="btn btn-black btn-md send_by"
                            onClick={() => requestHandler("eMail")}
                          >
                            <img src="images/gmail.png" alt="img" /> Send By
                            Email
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>}
      
      
    </React.Fragment>,
    document.getElementById("modal-root")
  )
}

export default DeliveryServiceFormModal