import { Alert } from "@mui/material";
import React from "react";
import ChatPopup from "./ChatPopup";

const Footer = () => {
  return (
    <React.Fragment>
      <section className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="single_footer">
                <h4>Asset Search & Validation Services</h4>
                <ul className="footer_links">
                  <li>Open Plots</li>
                  <li>Flats/Apartments/Individual homes</li>
                  <li>Farm Lands</li>
                </ul>
              </div>
              <div className="single_footer">
                <h4>Home Services</h4>
                <ul className="footer_links">
                  <li>Pest Control</li>
                  <li>Home Cleaning</li>
                  <li>Office Cleaning</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single_footer">
                <h4>Rental Services</h4>
                <ul className="footer_links">
                  <li>Apartments</li>
                  <li>Offices</li>
                  <li>Individual Houses</li>
                  <li>Commercial Properties</li>
                </ul>
              </div>
              <div className="single_footer">
                <h4>Online/Offline Advertising</h4>
                <ul className="footer_links">
                  <li>LED Screen Rentals</li>
                  <li>Auto Campaigns</li>
                  <li>Leaflets Distribution</li>
                  <li>Banners Pasting</li>
                  <li>Digital Marketing</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single_footer">
                <h4>Packers & Movers</h4>
                <ul className="footer_links">
                  <li>House Shifting</li>
                  <li>Office Shifting</li>
                </ul>
              </div>
              <div className="single_footer">
                <h4>Loans & Credit Cards</h4>
                <ul className="footer_links">
                  <li>Educational loan</li>
                  <li>Mortgage loan</li>
                  <li>Personal loan</li>
                  <li>Home Loan</li>
                  <li>Car Loan</li>
                  <li>Two-Wheeler Loan</li>
                  <li>Credit Card</li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="single_footer">
                <h4>Follow Us</h4>
                <ul className="footer_links social_links">
                  <li>
                    <a
                      href="https://www.facebook.com/Eesy-104450281745406/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/invites/contact/?i=aqycdroyq4zt&utm_content=ocp1wte"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youtube.com/channel/UCrvvsGPtt_a66x5m3hsprgg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/message/2PTFCUPRRAE7E1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-whatsapp"></i> whatsapp
                    </a>
                  </li>
                </ul>
              </div>
              <div className="single_footer">
                <h4>Central office</h4>
                <ul className="footer_links">
                  <li>8-2-334/13, 3rd Floor Green Valley,</li>
                  <li>Road No.3, Beside TV9 office, </li>
                  <li> Banjarahills,Hyderabad,</li>
                  <li>500034, India</li>
                </ul>
              </div>
              {/* <div className="single_footer">
                <h4>Corporate Office 2</h4>
                <ul className="footer_links">
                  <li>I Labs Technology Park,</li>
                  <li>Level 2, Oval Building,</li>
                  <li>Gate No 6, Plot no.18,</li>
                  <li>In orbit Mall Road,</li>
                  <li>Hyderabad, Telangana,</li>
                  <li>500081, India</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="copyright_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="copyright_div">
                <p>Copyright © 2022 Eesy.in.</p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="copy_links">
                <li>Disclaimer</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <Login />
      <Register /> */}

      <ChatPopup />
    </React.Fragment>
  );
};

export default Footer;
